import React, { useState, useEffect } from 'react'
import NavBar from '../components/NavBar';
import Error from '../components/Error';
import Loader from '../components/Loader';
import Footer from '../components/Footer';
import './JudoFilozofia.css'



function JudoFilizofia(props) {
    const pageName = "JudoFilizofia.js"
    const config = require('../config')
    const debug = config.DEBUG
    if(debug) { console.log(pageName) }

    const [completed, setCompleted] = useState(false)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        if(debug) { console.log(pageName + ' loading ...') }

        // things to do 

        if(debug) { console.log(pageName + ' loading done.') }
        setCompleted(true)
        setLoading(false)
        setError(false)
    }, [debug])


    return (
        <div className="container">
            <NavBar />

            {loading && <Loader />}
            {error && <Error />}
            {completed ? (<DefaultContent />) : (<Error />)}

            <Footer />
        </div>


    )

}

const DefaultContent = () => {
    return (

        <div>
            <div className="jumbotron my-4">

                <h1 className="display-4">Filozofia a symboly</h1>     
           
            </div>

            <div id="filozofia">
                <blockquote class="quote-card text-center">
                    <cite>Jigoro Kano hovorí o dvoch hlavných princípoch v Judo, ktoré sa od vzniku umenia objavili:</cite>
                    <p>"Najlepšie využitie energie" a "Vzájomný blahobyt"</p>                    
                </blockquote>

                <p>Judo nebolo pôvodne brané ako jedno z odvetví športu, ale ako vojenské umenie. Preto ak sa chceme brániť akémukoľvek útoku, musíme čo možno najlepšie využiť svoju mentálnu energiu a fyzickú silu. Toto platí rovnakou mierou pre obranu, tak aj pre útok. Jigoro Kano kládol dôraz pri výučbe Judo na to, že ak vieme využiť tento princíp pri útoku, alebo obrane, vieme ho využiť aj v iných oblastiach.</p>
                <p>Na svete sa musí vykonať mnoho vecí a nech je predmetom nášho snaženia čokoľvek, najbežnejším princípom pre úspech je najefektívnejšie využitie tela a mysle.</p>
                <p>Judo totiž nie je návodom na čo najlepšie použitie duševnej a fyzickej sily pri útoku a obrane, je to skôr návod na prijatie a použitie tohto princípu vo všetkých oblastiach života. Takto sa čo "Najlepšie využitie energie" stáva základným princípom, ktorého aplikáciou sa dá dosiahnuť napredovanie a rozvoj ľudskej osobnosti.</p>

                <h4><small>Symboly Juda - význam a symbolika Gi</small></h4>

                <p>So slovom Kimono sa stretávame pri cvičení bojových umení veľmi často. Je potrebné si však uvedomiť, že kimono je slávnostný odev. V Kimono sa vydávajú japonské ženy, vykonávajú sa v nich rôzne slávnostné obrady a obliekajú sa pri národných sviatkoch a tradičných slávnostiach. Je krásne vyšívané a rôznofarebné. To je Kimono. Gi má takisto rôznu farbu. Vždy je však jednofarebné. Judo-gi má vo svojej podstate farbu bielu. (S modrou sa stretávame len na ME pre lepšie rozoznanie judistov.) Judo-gi sa skladá z dvoch dielov, z horného a dolného, z hrubšieho a tenšieho, z roztvoreného a uzavretého proste jing - jang. Gi nám symbolizuje teda celý svet, celú prírodu, všetky zákony života, je vlajkou víťazstva nad naším životom. Pokiaľ ho nebudeme rešpektovať a ctiť, stráca všetku svoju moc a nemá väčšiu hodnotu ako balíček handier. Judo-Gi si neobliekame len preto, aby sme si ušetrili civilný odev, obliekame si ho preto, lebo je spojené s históriou, tradíciami a s duchovnými hodnotami Judo.</p>

                <h4><small>Symboly Juda - význam a symbolika Obi</small></h4>

                <p>Obi je opasok všetkých cvičencov Budo. Je vyrobený z textilu alebo z čistého hodvábu. Slúži na upravenie a priviazanie Judo-Gi. V minulosti mnohí adepti Judo nosili obi aj pod blúzou civilného šatstva. Bol to predmet ich úcty, duch majstra a Judo. Nosenie pásu im stále pripomínalo etiketu Judo, a tým usmerňovalo ich správanie aj na verejnosti. Už pri opásavaní obi zistíme, že to nie je len obyčajný opasok slúžiaci len na upevnenie kabátca. Cvičenec si ho obtáča dvakrát okolo pása tak, aby sa za chrbtom neprekrížil. V literatúrach sa uvádzajú dve príčiny, prečo sa nesmie prekrížiť. Prvé hľadisko je, že každý záhyb nachádzajúci sa na chrbte, pri páde bolí. Druhé hľadisko, uvádzané starými majstrami, ktoré sa zachovalo len v ústnej podobe, hovorí, že by došlo ku skríženiu myšlienok a opusteniu princípu Do. Majster totiž odovzdávaním pásu, odovzdával aj svoju myseľ a myseľ všetkých majstrov jeho línie. Odovzdaný pás bol symbolom autentičnosti Cesty.</p>

                <p>Prvé čo nezainteresovaného pozorovateľa zaujme, je farebnosť Obi. Pôvodne malo obi tri farby, bielu, hnedú a čiernu. Aj tieto tri farby, ako aj mnohé iné veci v Budó, majú korene v dávnej minulosti a samozrejme v Budhizme.</p>

                <p>Jeden z mnohých mýtov o vzniku farebnosti Obi hovorí, že keď začíname praktikovať za-zen náš pás je biely. Po uplynutí niekoľkých mesiacov sa pás špiní. Japonci sú presvedčení, že to nie je špina zvonku, ale že sa očisťuje ich myseľ. Obi začína mať hnedú farbu. Po uplynutí niekoľkých rokov praxe je naša myseľ už takmer čistá, to znamená, že všetko to zlo, tá špina je zachytená na Obi. Obi je už také znečistené, že má čiernu farbu. Ak je naša myseľ čistá, uplne čistá, nepripútaná k nijakým hmotným statkom a svetským radostiam, dokáže naša čistá myseľ očistiť aj naše telo, náš odev, naše okolie. Pravý Budó-ka sa však neuspokojí s dosiahnutým a stále na sebe pracuje, ako fyzicky, tak aj duševne. Na postupne vybledajúcom páse sa začína objavovať červené zafarbenie. Je to neklamný znak toho, že cvičenec pri dosahovaní vytýčeného cieľa prešiel veľkým utrpením a červené zafarbenie, nie je nič iné ako krvavé škvrny. A tak tu máme ďalší pás, na ktorom sa striedajú červené a biele pruhy. No a po celoživotnej tvrdej práci, červené škvrny pribúdajú až sa Obi stáva krvavo červeným. Legenda hovorí, že Jigoro Kano si po červenom Obi obliekol opäť Obi biele. Tento akt bol sprevádzaný jeho slovami: "Zo žiaka sa stal majster, no aj majster je stále iba žiakom".</p>

                <p>Dnes sme svedkami toho, že pás dostane ktokoľvek po zvládnutí určitých techník. Z predchádzajúcich viet však jasne vyplýva, že v minulosti sa Obi odovzdávalo nielen cvičencom, ktorí boli len dobre technicky vybavení, ale cvičencom, ktorí mali potrebné technické a aj charakterové vlastnosti. Že tieto slová sú pravdivé, o tom svedčia aj dochované listiny, čo všetko sa vyžadovalo od adepta, ktorý sa pripravoval na prijatie pásu. Pred prijatím pásu sa musel adept vykúpať, ostrihať si vlasy a obliecť čisté, poprípade nové Judo - Gi. Zvlášť si musel očistiť ruky, ústa, uši, nos a oči, čo znamenalo očistenie piatich zmyslov. Bola to skutočne viac udalosť duchovná ako technická. Keď si judista oblieka čierny pás, zmení a ovplyvní to jeho správanie. To je "sila Obi". Japonci sa domnievajú, že pás nie je hmotný, ale je to symbol života, vesmírnej pravdy.</p>

                <p>Aj profesor Jigoro Kano zaviedol pôvodne farebné rozlíšenie žiackých a majstrovských stupňov podľa starých mýtov a legiend. Neskôr sa u žiackých stupňov technickej vyspelosti zaviedlo bohatšie farebné značenie, ktoré však takisto nebolo navrhnuté len na rozlíšenie, ale takisto má filozofický - symbolický podtext.</p>

                <h4><small>Biele Obi</small></h4>

                <p>Táto farba označuje nevinnosť, neskúsenosť. Stretol som sa aj s termínom panenská čistota. Je to farba vedomia, ktoré je nedotknuté (samozrejme len čo sa týka Budo), prázdne. Je to farba začiatočníkov.</p>

                <h4><small>Žlté Obi</small></h4>

                <p>Žltá farba je farbou ranného slnka, ktoré je zase symbolom nového dňa, prichádzajúceho svetla, vďaka ktorému na stromoch dozrievajú plody. Je to farba vedomia, ktoré začína chápať, je to farba úsvitu a odhalenia nových horizontov.</p>

                <h4><small>Oranžové Obi</small></h4>

                <p>Oranžová farba je takisto farbou slnka, no už na sklonku dňa. Je to farba vedomia, ktoré už pochopilo a našlo si svoje miesto. Nositeľ tohoto Obi už neodhaľuje nové horizonty, ale sa k nim obracia a vydáva sa im v ústrety.</p>

                <h4><small>Zelené Obi</small></h4>

                <p>Zelená je farba lesa, trávy, prírody. Zelená patrí tomu, kto je schopný spracovať a využiť slnečné svetlo, tomu kto je schopný tvoriť nové myšlienky a hľadať nové riešenia.</p>

                <h4><small>Modré Obi</small></h4>

                <p>Modrá je farbou oceánu, vôd a neba. Modré Obi zasluhuje ten, koho túžby a snaženie siahajú až k ďalekým horizontom. Jeho myseľ musí byť priezračná ako voda z bystrín Hory purpurového mraku (v Taoistických spisoch je to posvätná hora). Nositeľ modrého Obi ešte neobjavil tajomstvá hlbín mora a výšav vesmíru, ale je už schopný druhým dávať. "Nebo dáva život miliónom, aby tí laskaví mali možnosť praktikovať svoju dobrosrdečnosť, a aby dobrí ľudia mohli hromadiť dobro: a tak rozšíriť svoju myseľ k ochrane a opatrovaniu veľkého množstva ľudí".</p>

                <h4><small>Hnedé Obi</small></h4>

                <p>Hnedá farba je farbou pôdy, do ktorej keď zasadíme semienko, tak môže vzklíčiť. Hnedá je farbou trvanlivosti a spoľahlivosti. Technické schopnosti tohoto človeka sú už na dostatočne vysokej úrovni, jeho vedomie je vedomím zeme - je plodné a jeho myseľ je plodná a neoblomná.</p>

                <h4><small>Čierne Obi</small></h4>

                <p>Čierna farba je farbou noci. Noc však neznamená koniec, ale je to predzvesť nového dňa a opäť východu slnka. Je to farba záväzku voči Budo. Cvičenec, ktorého obopína tento pás, je predurčený na to, aby ukázal vychádzajúce slnko novým adeptom Judo.</p>

                <h4><small>Červeno-biele Obi</small></h4>

                <p>Biela je farba duševnej čistoty, červená je farbou obetovania, oddanosti a pokory. Každý krok vlastníka tohoto pásu je krokom cez svoje ego. Pri cvičení Kata s Katana je každý rez, nie rezom do protivníka, ale rezom vlastného ega.</p>

                <h4><small>Červené Obi</small></h4>

                <p>Majitelia týchto Obi celý svoj život sa snažili prijať energiu zo Slnka. Táto energia, dokonalosť, duševná harmónia a obeta sú farbou červenou.</p>
            </div>


          

        </div>

    )
}


export default JudoFilizofia
