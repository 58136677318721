import React, { useState, useEffect } from 'react'
import NavBar from '../components/NavBar';
import Error from '../components/Error';
import Loader from '../components/Loader';
import Footer from '../components/Footer';


function Dokumenty(props) {
    const pageName = "Dokumenty.js"
    const config = require('../config')
    const debug = config.DEBUG
    if(debug) { console.log(pageName) }

    const [completed, setCompleted] = useState(false)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        if(debug) { console.log(pageName + ' loading ...') }

        // things to do 

        if(debug) { console.log(pageName + ' loading done.') }
        setCompleted(true)
        setLoading(false)
        setError(false)
    }, [debug])


    return (
        <div className="container">
            <NavBar />

            {loading && <Loader />}
            {error && <Error />}
            {completed ? (<DefaultContent />) : (<Error />)}

            <Footer />
        </div>


    )

}

const DefaultContent = () => {
    return (
        <div>
            <div className="jumbotron my-4">
                <div className="row m-0 p-0">

                    <div className="col-8">
                        <h1 className="display-4">Dokumenty</h1>
                        <h2 className="display-6">na stiahnutie</h2>
                    </div>

                    <div className="col-4 text-right">
                    </div>

                </div>
            </div>


            <div id="historia">
                {/* <hr /> */}
                {/* <h3 className="display-6">História klubu</h3> */}
    
                <ul>
                    <li><a href="/files/zapisnica2024.pdf" download>Zápisnica z členskej schôdze výkonného výboru_Judo klub Levice z 20.6.2024</a></li>
                </ul>
            </div>

        </div>

    )
}


export default Dokumenty
