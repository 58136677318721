import React, { useState, useEffect } from 'react'
import { Field, Formik, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import firebase from "../services/firebase"
//import { FaFilePdf } from 'react-icons/fa';
import NavBar from '../components/NavBar';
import Error from '../components/Error';
import Loader from '../components/Loader';
import Footer from '../components/Footer';


const SignInSchema = Yup.object().shape({
    first_name: Yup.string()
    .required('Pole meno je povinné vyplniť!'),
    last_name: Yup.string()
    .required('Pole prizviske je povinné vyplniť!'),
    date_of_birth: Yup.string()
    .required('Dátum narodenia je povinné vyplniť!'),
    street: Yup.string()
    .required('Pole ulica je povinné vyplniť!'),
    city: Yup.string()
    .required('Pole obec je povinné vyplniť!'),  
    zip: Yup.string()
    .required('Pole PSČ je povinné vyplniť!'),  
    gdpr: Yup.bool().oneOf([true], 'Je potredné udeliť súhlas GDPR.')
    })


function Prihlaska(props) {
    const pageName = "Prihlaska.js"
    const config = require('../config')
    const debug = config.DEBUG
    if(debug) { console.log(pageName) }

    const [completed, setCompleted] = useState(false)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        if(debug) { console.log(pageName + ' loading ...') }

        // things to do 

        if(debug) { console.log(pageName + ' loading done.') }
        setCompleted(true)
        setLoading(false)
        setError(false)
    }, [debug])



    return (
        <div className="container">
            <NavBar />

            {loading && <Loader />}
            {error && <Error />}
            {completed ? (<DefaultContent />) : (<Error />)}

            <Footer />
        </div>


    )

}

const DefaultContent = (props) => {
    const [completed, setCompleted] = useState(false)

    const initialValues = {
        first_name: '',
        last_name: '',
        date_of_birth: '',
        street: '',
        city: '',
        zip: '',
        email: '',
        phone: '',
        gdpr: false,
    }      

    const sendEmail = () => {

    }
    
    const handleSubmit = (values) => {
        console.log(values)
        const itemsRef = firebase.database().ref('prihlasky');
        itemsRef.push(values)

        sendEmail()

        setCompleted(true)
    }

    if(!completed) {

        return (

            <div>
                <div className="jumbotron my-4">
    
                <h1 className="display-4">Nezáväzná prihláška do</h1>
                <h2 className="display-6">športového klubu Judo Levice</h2>
                </div>
    
    
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    // onSubmit={(values, { setSubmitting }) => {
                    //     setTimeout(() => {
                    //     alert(JSON.stringify(values, null, 2));
                    //     setSubmitting(false);
                    //     }, 400);
                    // }}
                    validationSchema={SignInSchema}
                >
                {({ dirty, isValid, errors, touched,  }) => (
                    
                <Form autoComplete="off">
                {/* <div className="errors">
                    {errors.username && touched.username && <div>{errors.username}</div>}
                    <ErrorMessage name="password" />
                </div> */}
        
        
                <div>
    


                <div className="card">
                    <div className="card-body login-card-body">
        
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Meno</label>
                            <div className="col-sm-9">
                            <Field name="first_name" type="text" autoComplete="off" className="form-control" />            
                            <ErrorMessage name="first_name" />
                            </div>
                        </div>
        
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Priezvisko</label>
                            <div className="col-sm-9">
                            <Field name="last_name" type="text" autoComplete="off" className="form-control" />            
                            <ErrorMessage name="last_name" />
                            </div>
                        </div>
        
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">narodený</label>
                            <div className="col-sm-9">
                            <Field name="date_of_birth" type="date" autoComplete="off" className="form-control" />            
                            <ErrorMessage name="date_of_birth" />
                            </div>
                        </div>
        
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">ulica</label>
                            <div className="col-sm-9">
                            <Field name="street" type="text" autoComplete="off" className="form-control" />                
                            <ErrorMessage name="street" />
                            </div>                
                        </div>
        
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">obec</label>
                            <div className="col-sm-9">
                            <Field name="city" type="text" autoComplete="off" className="form-control" />                
                            <ErrorMessage name="city" />
                            </div>                
                        </div>
        
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">PSČ</label>
                            <div className="col-sm-9">
                            <Field name="zip" type="text" autoComplete="off" className="form-control" />                
                            <ErrorMessage name="zip" />
                            </div>                
                        </div>
                        
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">e-mail</label>
                            <div className="col-sm-9">
                            <Field name="email" type="text" autoComplete="off" className="form-control" />                
                            <ErrorMessage name="email" />
                            </div>                
                        </div>
        
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">telefón</label>
                            <div className="col-sm-9">
                            <Field name="phone" type="text" autoComplete="off" className="form-control" />                
                            <ErrorMessage name="phone" />
                            </div>                
                        </div>
        
                        <div className="col-12">
                            <div className="form-group form-check">
                                <Field type="checkbox" name="gdpr" className={'form-check-input ' + (errors.gdpr && touched.gdpr ? ' is-invalid' : '')} />
                                <label htmlFor="gdpr" className="form-check-label">Udeľujem súhlas na spracovanie osobných údajov pre účely náboru 2020 (GDPR).</label>
                                <ErrorMessage name="gdpr" component="div" className="invalid-feedback" />
                                <hr />
                                <p>Všetky informácie sú dôverné a budú použité výlučne pre potreby náboru 2020.</p>
                                {/* <p><a href="http://judolevice.sk/files/Info_gdpr_judoklub.pdf" target="_blank">Dokument k spracúvaniu osobných údajov.</a></p> */}
                            </div>
    
                        {/* <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey="Your client site key"
                            onChange={onChange}
                        /> */}                
                        </div>
        
        
                        <div className="col-12">
                            {/* <button type="submit" className="btn btn-primary btn-block">Odoslat</button> */}
                            <button type="submit" disabled={!isValid || !dirty} className="btn btn-primary btn-block">Odoslat</button>
                        </div>
                        
        
                    
                    </div>
        
                </div>
                
                
                </div>
        
                </Form>
                )}
        
                </Formik>
            </div>
    
        )


    } else {


        return (

            <div>

                <div className="card my-4">
                    <div className="card-body login-card-body">                        
                        <h3>Nezáväzná prihláška do Športového klubu Judo Levice</h3>
                        <hr />
                        <div className="alert alert-success" role="alert">
                        <h4 className="alert-heading">Ďakujeme</h4>
                        <hr />
                        <p className="mb-0">vaša prihláška bola zaevidovaná.</p>
                        </div>

                    </div>
                </div>

            </div>
    
        )

    }

}


export default Prihlaska
