import React, { useState, useEffect } from 'react'
import NavBar from '../components/NavBar';
import Error from '../components/Error';
import Loader from '../components/Loader';
import Footer from '../components/Footer';


function Treningy(props) {
    const pageName = "Treningy.js"
    const config = require('../config')
    const debug = config.DEBUG
    if(debug) { console.log(pageName) }

    const [completed, setCompleted] = useState(false)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        if(debug) { console.log(pageName + ' loading ...') }

        // things to do 

        if(debug) { console.log(pageName + ' loading done.') }
        setCompleted(true)
        setLoading(false)
        setError(false)
    }, [debug])


    return (
        <div className="container">
            <NavBar />

            {loading && <Loader />}
            {error && <Error />}
            {completed ? (<DefaultContent />) : (<Error />)}

            <Footer />
        </div>


    )

}

const DefaultContent = () => {
    return (
        <div>
            <div className="jumbotron my-4">

                <h1 className="display-4">Tréningy</h1>
                <h2 className="display-6">časový harmonogram tréningov 2023/2024</h2>
            </div>

            <div>

                <table className="table table-bordered">
                <tbody>
                    <tr>
                    <th scope="row">Pondelok</th>
                    <td className="bg-primary text-center">
                        <strong>Skupina č. 2</strong>
                        <div><small>16:00 - 17:30<br />M.Fedor</small></div>
                    </td>
                    <td className="bg-danger text-center">
                        <strong>Skupina č. 1</strong>
                        <div><small>17:30 - 19:00<br />M.Fedor</small></div>
                    </td>
                    <td className="bg-warning text-center">
                        <strong>Dorast - muži</strong>
                        <div><small>19:00 - 20:30<br />L.Kossuth a O.Kossuth</small></div>
                    </td>
                    </tr>
                    <tr>
                    <th scope="row">Utorok</th>
                    <td className="bg-info text-center">
                        <strong>Skupina č. 4</strong>
                        <div><small>16:00 - 17:00<br />L.Kossuth a O.Kossuth</small></div>
                    </td>
                    <td className="bg-success text-center">
                        <strong>Skupina č. 3</strong>
                        <div><small>17:30 - 19:00<br />L.Kossuth a O.Kossuth</small></div>
                    </td>
                    <td className="bg- text-center">
                        {/* <strong>Hobby judo</strong> */}
                        {/* <div><small>19:00 - 21:30<br />L.Kossuth a O.Kossuth</small></div> */}
                    </td>
                    </tr>
                    <tr>
                    <th scope="row">Streda</th>
                    <td className="bg-primary text-center">
                        <strong>Skupina č. 2</strong>
                        <div><small>16:00 - 17:30<br />M.Fedor</small></div>
                    </td>
                    <td className="bg-danger text-center">
                        <strong>Skupina č. 1</strong>
                        <div><small>17:30 - 19:00<br />M.Fedor</small></div>
                    </td>
                    <td className="bg-warning text-center">
                        <strong>Dorast - muži</strong>
                        <div><small>19:00 - 20:30<br />L.Kossuth a O.Kossuth</small></div>
                    </td>
                    </tr>
                    <tr>
                    <th scope="row">Štvrtok</th>
                    <td className="bg-info text-center">
                        <strong>Skupina č. 4</strong>
                        <div><small>16:00 - 17:00<br />L.Kossuth a O.Kossuth</small></div>
                    </td>
                    <td className="bg-success text-center">
                        <strong>Skupina č. 3</strong>
                        <div><small>17:30 - 19:00<br />L.Kossuth a O.Kossuth</small></div>
                    </td>
                    <td className="bg- text-center">
                        {/* <strong>Hobby judo</strong> */}
                        {/* <div><small>19:00 - 21:30<br />L.Kossuth a O.Kossuth</small></div> */}
                    </td>
                    </tr>
                    <tr>
                    <th scope="row">Piatok</th>
                    <td className="bg-primary text-center">
                        <strong>Skupina č. 2</strong>
                        <div><small>16:00 - 17:30<br />M.Fedor</small></div>
                    </td>
                    <td className="bg-danger text-center">
                        <strong>Skupina č. 1</strong>
                        <div><small>17:30 - 19:00<br />M.Fedor</small></div>
                    </td>
                    <td className="bg-warning text-center">
                        <strong>Dorast - muži</strong>
                        <div><small>19:00 - 20:30<br />L.Kossuth a O.Kossuth</small></div>
                    </td>
                    </tr>
                </tbody>
                </table>

            </div>

            <hr />

            <div className="row">
                <div className="col-7">
                    <h3>VYSVETLIVKY </h3>
                    <ul>
                        <li>1.SKUPINA - rok narodenia 2008 - 2011</li>
                        <li>2.SKUPINA - rok narodenia 2011 - 2013</li>
                        <li>3.SKUPINA - rok narodenia 2013 - 2015</li>
                        <li>4.SKUPINA - rok narodenia 2016 - 2017</li>
                        <li>DORAST - MUŽI - rok narodenia 2007 - NEOBMEDZENE</li>
                    </ul>

                </div>


                <div className="col-5">
                    <h3>Členský prispevok</h3>
                    <ul>
                        <li>Mesačný Členský príspevok 40 &euro; </li>
                        <ul>
                            <li>2 súrodenci 35 &euro; / mesiac</li>
                            <li>3 súrodenci 30 &euro; / mesiac</li>
                        </ul>
                        {/* <li>Mesačná permanentka deti a študenti - 25 &euro;</li>
                        <li>Mesačná permanentka dorast a muži - 30 &euro;</li> */}
                        <li>Jednorazový vstup 5 &euro;</li>
                    </ul>
                </div>
            </div>


            			
			
			
			
        </div>

    )
}


export default Treningy
