// NavBar.js

import React from 'react'
import { Link } from "react-router-dom"
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { FaFacebook, FaInstagram } from 'react-icons/fa';


function NavBar() {

  function MyMenuLink({ label, to, favIcon, activeOnlyWhenExact }) {
    // let match = useRouteMatch({
    //   path: to,
    //   exact: activeOnlyWhenExact,
    //   favIcon: favIcon
    // });

    return (
      <Link to={to} className="nav-link">
        {label}
        {/* <span className="badge badge-info right">2</span> */}
      </Link>
      //   <div className={match ? "active" : ""}>
      //     {match && "> "}
      //     <Link to={to}>{label}</Link>
      //   </div>
    )
  }


  return (
    <header>


      <div className="container">
        <div className="row">
          <div className="col-sm-6">
            <img src="/images/logo.png" alt="JUDO klub Levice" />
          </div>
          <div className="col-sm-3">
            <img src="/images/jigoro.png" alt="Jigoro Kano" />
          </div>
          <div className="col-sm-3 text-right pt-4">
            <ul className="list-inline" >
              <li className="list-inline-item"><a href="https://www.instagram.com/judolevice/" target="_blank" rel="noopener noreferrer"><FaInstagram size={32} /></a></li>
              <li className="list-inline-item"><a href="https://www.facebook.com/Judo-klub-Levice-919225598112392" target="_blank" rel="noopener noreferrer"><FaFacebook size={32} /></a></li>
            </ul>
                        
            {/* 
            <div className="pt-4">
            </div> 
            */}

          </div>
        </div>
      </div>

      <Navbar expand="lg" variant="dark" bg="dark">
        <Navbar.Brand href="/">JUDO klub Levice</Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <MyMenuLink
              activeOnlyWhenExact={true}
              to="/"
              label="Oznamy"
            />
            <MyMenuLink
              activeOnlyWhenExact={true}
              to="/o-nasom-klube"
              label="O našom klube"
            />

            <NavDropdown title="O jude" id="basic-nav-dropdown">
              <NavDropdown.Item href="#/skusobny-poriadok-kyu">Skúšobný poriadok KYU</NavDropdown.Item>
              <NavDropdown.Item href="#/skusobny-poriadok-dan">Skúšobný poriadok DAN</NavDropdown.Item>
              <NavDropdown.Item href="#/judo">Historia JUDA</NavDropdown.Item>
              <NavDropdown.Item href="#/judo-moralny-kodex">Moralny kódex</NavDropdown.Item>
              <NavDropdown.Item href="#/judo-filozofia">Filozofia a symboli</NavDropdown.Item>
              <NavDropdown.Item href="#/judo-etika">Etika a JUDO</NavDropdown.Item>
              <NavDropdown.Item href="#/dokumenty">Dokumenty</NavDropdown.Item>
            </NavDropdown> 

            <MyMenuLink
              activeOnlyWhenExact={true}
              to="/treningy"
              label="Tréningy"
            />
            <MyMenuLink
              activeOnlyWhenExact={true}
              to="/2-percenta"
              label="Venujte nám 2%"
            />
            <MyMenuLink
              activeOnlyWhenExact={true}
              to="/gdpr"
              label="GDPR"
            />
            <MyMenuLink
              activeOnlyWhenExact={true}
              to="/prihlaska"
              label="Prihláška"
            />
                       
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>

  )
}

export default NavBar



