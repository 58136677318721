import React, { useState, useEffect } from 'react'
import { FaFilePdf } from 'react-icons/fa';
import NavBar from '../components/NavBar';
import Error from '../components/Error';
import Loader from '../components/Loader';
import Footer from '../components/Footer';


function SkusobnyPoriadokKyu(props) {
    const pageName = "SkusobnyPoriadokKyu.js"
    const config = require('../config')
    const debug = config.DEBUG
    if(debug) { console.log(pageName) }

    const [completed, setCompleted] = useState(false)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        if(debug) { console.log(pageName + ' loading ...') }

        // things to do 

        if(debug) { console.log(pageName + ' loading done.') }
        setCompleted(true)
        setLoading(false)
        setError(false)
    }, [debug])


    return (
        <div className="container">
            <NavBar />

            {loading && <Loader />}
            {error && <Error />}
            {completed ? (<DefaultContent />) : (<Error />)}

            <Footer />
        </div>


    )

}

const DefaultContent = () => {
    return (
        <div>
            <div className="jumbotron my-4">
                <h1 className="display-4">Skusobý poriadok Slovenského zväzu JUDO</h1>
                <h2 className="display-6"><a href="/files/skusobny_poriadok.pdf" download><FaFilePdf />skusobny_poriadok.pdf</a></h2>
            </div>

            <div className="text-center">

                <div className=""> 
                    <img src="/images/sp01.png" alt="" />
                </div>
                <div className=""> 
                    <img src="/images/sp02.png" alt="" />
                </div>
                <div className=""> 
                    <img src="/images/sp03.png" alt="" />
                </div>
                <div className=""> 
                    <img src="/images/sp04.png" alt="" />
                </div>
                <div className=""> 
                    <img src="/images/sp05.png" alt="" />
                </div>
                <div className=""> 
                    <img src="/images/sp06.png" alt="" />
                </div>
                <div className=""> 
                    <img src="/images/sp07.png" alt="" />
                </div>
                <div className=""> 
                    <img src="/images/sp08.png" alt="" />
                </div>



            </div>


        </div>

    )
}


export default SkusobnyPoriadokKyu
