import React, { useState, useEffect } from 'react'
import NavBar from '../components/NavBar';
import Error from '../components/Error';
import Loader from '../components/Loader';
import Footer from '../components/Footer';
import './Oznamy.css'
// import DvePercenta from '../components/DvePercenta';
// import { FaFilePdf } from 'react-icons/fa';

//import { FacebookProvider, EmbeddedPost } from 'react-facebook';


function Oznamy(props) {
    const pageName = "Oznamy.js"
    const config = require('../config')
    const debug = config.DEBUG
    if(debug) { console.log(pageName) }

    const [completed, setCompleted] = useState(false)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        if(debug) { console.log(pageName + ' loading ...') }

        // things to do 

        if(debug) { console.log(pageName + ' loading done.') }
        setCompleted(true)
        setLoading(false)
        setError(false)
    }, [debug])

    return (
        <div className="container">
            <NavBar />

            {loading && <Loader />}
            {error && <Error />}
            {completed ? (<DefaultContent />) : (<Error />)}

            <Footer />
        </div>


    )

}

const DefaultContent = (props) => {
    return (

        <div className="container" style={{ marginTop: 30 }}>
            <div className="row">
                <div className="col-sm-6">
                    <div>
                        {/* <h2>Header</h2> */}
                        
                        {/* <div>
                            <img src="/images/home01.jpg" className="img-fluid" alt="JUDO klub Levice" />
                        </div>
                        <hr /> */}

                        {/* <h3>Venujte nám 2%</h3>*/}
                        <div>
                            {/* <a href="http://localhost:3000/#/prihlaska"><img src="/images/nabor2020.jpg" className="img-fluid" alt="Judo klub Levice - nabor novych clenov 2020" /></a> */}
                            {/* <h2 className="display-6"><a href="/files/tlacivo-2percenta.pdf" download><FaFilePdf /> Predvyplnené tlačivo 2 percent</a></h2> */}
                            {/* <img src="/images/jkl_nabor2023.jpg" className="img-fluid" alt="Judo klub Levice - nabor 2023" /> */}
                            {/* <img src="/images/letnytabor.jpeg" className="img-fluid" alt="LETNY TABOR" /> */}
                            <img src="/images/nabor2024.png" className="img-fluid" alt="JUDO KLUB Levice" />
                            {/* <DvePercenta /> */}
                            {/* <img src="/images/jkl_nabor2022.jpg" className="img-fluid" alt="Judo klub Levice - nabor 2022" /> */}
                        </div>

                    </div>
                </div>

                <div className="col-sm-6">
                    <p><strong>M.R.Štefánika 1 (OD Pokrok)</strong></p>
                    <div className="map-responsive">
                    <iframe title="gmaps" src="https://www.google.com/maps/embed?pb=!4v1592497331241!6m8!1m7!1s3josle_gTV91DGD1uNKvOw!2m2!1d48.21775341025435!2d18.60189350404534!3f22.962853420663762!4f-15.223877237002554!5f0.7820865974627469" frameborder="0" allowfullscreen></iframe>
                    </div>
	            <hr />
                    <div className="pt-30 mt-30">
			<img src="/images/kodex.jpg" className="img-fluid" alt="Kodex" />	
		    </div>
                    {/*<p>Some text about me in culpa qui officia deserunt mollit anim..</p>
                    <h3>Some Links</h3>
                    <p>Lorem ipsum dolor sit ame.</p> */}
                    {/* <ul className="nav nav-pills flex-column">
                        <li className="nav-item">
                            <a className="nav-link active" href="#">Active</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">Link</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">Link</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link disabled" href="#">Disabled</a>
                        </li>
                    </ul>
                    <hr className="d-sm-none" /> */}
                </div>


            </div>
        </div>








        //     <div>

        //         <div className="jumbotron my-4">
        //             <h1 className="display-3">A Warm Welcome!</h1>
        //             <p className="lead">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa, ipsam, eligendi, in quo sunt possimus non incidunt odit vero aliquid similique quaerat nam nobis illo aspernatur vitae fugiat numquam repellat.</p>
        //             <a href="#" className="btn btn-primary btn-lg">Call to action!</a>
        //         </div>


        //     <div className="row mt-5">
        //     <div className="col-lg-6 mb-4 grid-margin">
        //         <div className="card h-100">
        //             <h4 className="card-header">Card Title</h4>
        //             <div className="card-body text-center">
        //                 <iframe src="//maps.google.sk/maps?hl=sk&amp;f=q&amp;source=s_q&amp;geocode=&amp;q=levice,+M.r.+štefánika+1&amp;t=m&amp;ie=UTF8&amp;hq=&amp;hnear=levice,+M.r.+štefánika+1&amp;z=15&amp;output=embed"></iframe>
        //             </div>
        //             <div className="card-footer">
        //             <Button variant="btn btn-primary">Learn More</Button>
        //             </div>
        //         </div>
        //     </div>
        //     <div className="col-lg-6 mb-4 grid-margin">
        //         <div className="card h-100">
        //             <h4 className="card-header">Card Title</h4>
        //             <div className="card-body" id="fb-jdk-container">
        //                 <div class='fb-page' data-href='https://www.facebook.com/Judo-klub-Levice-919225598112392/'  data-adapt-container-width='true' data-small-header='false' data-hide-cover='false' data-show-facepile='true' data-show-posts='true' />
        //             </div>
        //             <div className="card-footer">
        //             <Button variant="btn btn-primary">Learn More</Button>
        //             </div>
        //         </div>
        //     </div>
        //     <div className="col-lg-4 mb-4 grid-margin">
        //         <div className="card h-100">
        //             <h4 className="card-header">Card Title</h4>
        //             <div className="card-body">
        //             <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sapiente esse necessitatibus neque.</p>
        //             </div>
        //             <div className="card-footer">
        //             <Button variant="btn btn-primary">Learn More</Button>
        //             </div>
        //         </div>
        //     </div>
        // </div>

        //     </div>

    )
}


export default Oznamy
