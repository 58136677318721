import React, { useState, useEffect } from 'react'
import NavBar from '../components/NavBar';
import Error from '../components/Error';
import Loader from '../components/Loader';
import Footer from '../components/Footer';

function Blank(props) {
    const pageName = "Blank.js"
    const config = require('../config')
    const debug = config.DEBUG
    if(debug) { console.log(pageName) }

    const [completed, setCompleted] = useState(false)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        if(debug) { console.log(pageName + ' loading ...') }

        // things to do 

        if(debug) { console.log(pageName + ' loading done.') }
        setCompleted(true)
        setLoading(false)
        setError(false)
    }, [debug])


    return(
        <div className="container">
            <NavBar />
            
            { loading && <Loader /> }  
            { error && <Error /> }  
            { completed ? (<DefaultContent />) : (<Error />) }

            <Footer />
        </div>
        

    )

}

const DefaultContent = (props) => {
    return (
        <div>


        </div>

    )
}


export default Blank
