import React, { useState, useEffect } from 'react'
import NavBar from '../components/NavBar';
import Error from '../components/Error';
import Loader from '../components/Loader';
import Footer from '../components/Footer';

import { Field, Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import firebase from "../services/firebase"
import DataTable from 'react-data-table-component';

import { FaEllipsisH } from 'react-icons/fa';

function Applications(props) {
    const pageName = "Applications.js"
    const config = require('../config')
    const debug = config.DEBUG
    if(debug) { console.log(pageName) }

    const [completed, setCompleted] = useState(false)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        if(debug) { console.log(pageName + ' loading ...') }

        // things to do 

        if(debug) { console.log(pageName + ' loading done.') }
        setCompleted(true)
        setLoading(false)
        setError(false)
    }, [debug])



    return(
        <div className="container">
            <NavBar />
            
            { loading && <Loader /> }  
            { error && <Error /> }  
            { completed ? (<DefaultContent {...props} />) : (<Error />) }

            <Footer />
        </div>
        

    )

}

const DefaultContent = (props) => {
    const [items, setItems] = useState([])

    function calculateAge(birthday){
        let today = new Date(),
            //birthay has 'Dec 25 1998'
            dob = new Date(birthday),
            //difference in milliseconds
            diff = today.getTime() - dob.getTime(),
            //convert milliseconds into years
            years = Math.floor(diff / 31556736000),
            //1 day has 86400000 milliseconds
            days_diff= Math.floor((diff % 31556736000) / 86400000),
            //1 month has 30.4167 days
            months = Math.floor(days_diff / 30.4167),
            days = Math.floor(days_diff % 30.4167)
           
            console.log(`${years} years ${months} months ${days} days`);
        //    return `${years} years ${months} months ${days} days`;
           return `${years} rokov`
    }

    const getData = () => {
        const itemsRef = firebase.database().ref('prihlasky');
        itemsRef.on('value', (snapshot) => {
            let items = snapshot.val();
            let newState = [];
            for (let item in items) {
              newState.push({
                id: item,
                first_name: items[item].first_name,
                last_name: items[item].last_name,
                date_of_birth: items[item].date_of_birth,
                street: items[item].street,
                city: items[item].city,
                zip: items[item].zip,
                email: items[item].email,
                phone: items[item].phone,                
              })
            }

            setItems(newState)
            console.log(newState)
          })
          
    }
    
    useEffect(() => {

        getData()
        return () => {
            //cleanup
        }
    }, [])

    const data = items
    const columns = [
      {
        name: 'Meno',
        selector: 'first_name',
        sortable: true
      },
      {
        name: 'Priezvisko',
        selector: 'last_name',
        sortable: true,
        //right: true
      },
      {
        name: 'Narodeny',
        sortable: true,
        cell: row => 
        <div className="btn-group">
            {Intl.DateTimeFormat('sk-SK',{year: 'numeric',month: 'short',day: '2-digit' }).format(Date.parse(row.date_of_birth))}
        </div>
      },  
      {
        name: 'Vek',
        cell: row => 
        <div className="btn-group">
            {calculateAge(row.date_of_birth)}
            {/* {Intl.DateTimeFormat('sk-SK',{year: 'numeric',month: 'short',day: '2-digit' }).format(Date.parse(row.date_of_birth))} */}
        </div>
        },    
      {
      name: 'Ulica',
      selector: 'street',
      sortable: true,
      },
      {
      name: 'Obec',
      selector: 'city',
      sortable: true,
      },
    //   {
    //   name: 'PSC',
    //   selector: 'zip',
    //   sortable: true,
    //   },
      {
      name: 'Email',
      selector: 'email',
      sortable: true,
      },
      {
      name: 'Telefon',
      selector: 'phone',
      sortable: true,
      },
      {
      cell: row => 
      <div className="btn-group">
      <button type="button" className="btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {/* <i className="fas fa-ellipsis-h"></i> */}
          <FaEllipsisH />
      </button>
      <div className="dropdown-menu dropdown-menu-right">
          <button className="dropdown-item" type="button" data-toggle="modal" data-target={`#edit-lg-`+row.id}>Editovat</button>
          <button className="dropdown-item" type="button" onClick={() =>{
            console.log("REMOVE ID : "+row.id)
            const itemRef = firebase.database().ref(`/prihlasky/${row.id}`);
            itemRef.remove();

          }}>Vymazat</button>
      </div>

      <MyModal      
            key={row.id}
            id={row.id}
            first_name={row.first_name}
            last_name={row.last_name}
            street={row.street}
            city={row.city}
            zip={row.zip}                                                
            phone={row.phone}
            email={row.email}
            date_of_birth={row.date_of_birth}
        />
      </div>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      }
    ]

    return (
        <div>
            <div className="card-header my-4">
                <h3 className="card-title">Zoznam prihlasenych na skolsky rok 2020 / 2021</h3>
            </div>
            <DataTable
                data={data}
                columns={columns}
            />

        </div>

    )





}



function MyModal(props) {
    const modal_id = `edit-lg-`+props.id

    return (     

        <Formik
        const initialValues = {{
            first_name: props.first_name,
            last_name: props.last_name,
            date_of_birth: props.date_of_birth,
            street: props.street,
            city: props.city,
            zip: props.zip,
            email: props.email,
            phone: props.phone,
        }} 
        validationSchema={Yup.object({
            first_name: Yup.string()
                .min(1, 'Must be 1 characters or more')
                .max(25, 'Must be 25 characters or less')
                .required('Required'),
            last_name: Yup.string()
                .min(1, 'Must be 1 characters or more')
                .max(25, 'Must be 25 characters or less')
                .required('Required'),
            // street: Yup.string(),
            // city: Yup.string(),
            zip: Yup.string()
                .test('len', 'Must be exactly 5 characters', val => val.length === 5),
            // state: Yup.string(),
            // phone: Yup.string(),
            email: Yup.string()
                .email('Invalid email address')
                .required('Required'),
            // date_of_birth: Yup.date()
            //     .format('YYYY-MM-DD', true)
            //     .ageRange('DD-MM-YYYY', 3, 120, "date of birth is not within the rage."),
            // language: Yup.string(),
            // sex: Yup.string(),
            // categories: Yup.string(),
            // roles: Yup.string(),
            // rank: Yup.string(),
            // children: Yup.string(),
        })}
//            onSubmit={handleSubmit}
        onSubmit={(values, { setSubmitting }) => {
//                setCompleted(false)

//                console.log("ID : "+values.id)
//                const itemsRef = firebase.database().ref(`/prihlasky/${props.id}`)
//                itemsRef.push(values)
    
//                getData()
//                setCompleted(true)
//                setSubmitting(false);
    

            console.log("ID : "+props.id)
            const itemRef = firebase.database().ref(`/prihlasky/${props.id}`)
            itemRef.set(values)
            //setCompleted(true)
            setSubmitting(false)
            //props.history.push("/prihlaseny");             
            window.location.reload()
        }}

        // onSubmit={(values, { setSubmitting }) => {
        //     console.log("ID : "+props.id)
        //     setTimeout(() => {
        //     alert(JSON.stringify(values, null, 2));
        //     setSubmitting(true);
        //     }, 400);

        //}}

        >      
        
        <div className="modal fade" id={modal_id}>
        <div className="modal-dialog modal-lg">
            <Form>

            <div className="modal-content">
            <div className="modal-header">
                <h4 className="modal-title">{props.last_name} {props.first_name}</h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
                </button>
            </div>
            <div className="modal-body">
                

            <div className="card-body">
                <div className="row">
                <div className="col-sm-6">
                    <div className="form-group">
                    <label>Krstne meno</label>
                    <Field name="first_name" type="text" className="form-control" placeholder="Krstne meno" />
                    <ErrorMessage name="first_name" className="badge badge-danger" />
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                    <label>Priezvisko</label>
                    <Field name="last_name" type="text" className="form-control" placeholder="Priezvisko" />
                    <ErrorMessage name="last_name" className="badge badge-danger" />
                    </div>
                </div>
                </div>
            </div>
            <legend><span>Adresa</span></legend>
            <div className="card-body">                
                <div className="row">
                <div className="col-sm-6">
                    <div className="form-group">
                    <label>Ulica</label>
                    <Field name="street" type="text" className="form-control" placeholder="Ulica" />
                    <ErrorMessage name="street" className="badge badge-danger" />
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                    <label>Mesto</label>
                    <Field name="city" type="text" className="form-control" placeholder="Mesto" />
                    <ErrorMessage name="city" className="badge badge-danger" />
                    </div>
                </div>
                </div>

                <div className="row">
                <div className="col-sm-6">
                    <div className="form-group">
                    <label>PSC</label>
                    <Field name="zip" type="text" className="form-control" placeholder="PSC" />
                    <ErrorMessage name="zip" className="badge badge-danger" />
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                    <label>Datum narodenia</label>
                    <Field name="date_of_birth" type="date" className="form-control date-of-birth float-right date-of-birth" />
                    <ErrorMessage name="date_of_birth" className="badge badge-danger" />
                    </div>
                </div>
                </div>
            </div>

            <legend><span>Kontaktné údaje</span></legend>
            <div className="card-body">                

                <div className="row">
                <div className="col-sm-6">
                    <div className="form-group">
                    <label>telefónny kontakt</label>
                    <Field name="phone" type="text" className="form-control" placeholder="telefónny kontakt" />
                    <ErrorMessage name="phone" className="badge badge-danger" />
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                    <label>emailový kontakt</label>
                    <Field name="email" type="text" className="form-control" placeholder="emailový kontakt" />
                    <ErrorMessage name="email" className="badge badge-danger" />
                    </div>
                </div>
                </div>

            </div>
            

            </div>
            <div className="modal-footer justify-content-between">
                <button type="button" className="btn btn-default" data-dismiss="modal">Zatvorit bez ulozenia</button>
                <button type="button" className="btn btn-primary" >Ulozit zmeny</button>
            </div>
            </div>
            {/* </form> */}
            </Form>

        </div>                                                    
        </div>
        </Formik>
    )
}

export default Applications
