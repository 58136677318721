import React, { useState, useEffect } from 'react'
import { FaFilePdf } from 'react-icons/fa';
import NavBar from '../components/NavBar';
import Error from '../components/Error';
import Loader from '../components/Loader';
import Footer from '../components/Footer';


function GDPR(props) {
    const pageName = "GDPR.js"
    const config = require('../config')
    const debug = config.DEBUG
    if(debug) { console.log(pageName) }

    const [completed, setCompleted] = useState(false)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        if(debug) { console.log(pageName + ' loading ...') }

        // things to do 

        if(debug) { console.log(pageName + ' loading done.') }
        setCompleted(true)
        setLoading(false)
        setError(false)
    }, [debug])


    return (
        <div className="container">
            <NavBar />

            {loading && <Loader />}
            {error && <Error />}
            {completed ? (<DefaultContent />) : (<Error />)}

            <Footer />
        </div>


    )

}

const DefaultContent = () => {
    return (
        <div>
            <div className="jumbotron my-4">

                <h1 className="display-4">Spracúvanie osobných údajov</h1>
                <h2 className="display-5">v Judo klub Levice Slovensko, o.z.</h2>
            </div>

            <div>
                <p>Judo klub Levice Slovensko, o.z. so sídlom Ľ. Podjavorinskej 13, 934 01 Levice, IČO 42371694 (ďalej
                len ako "prevádzkovateľ") spracúva osobné údaje a zabezpečuje ich ochranu v súlade s požiadavkami
                Nariadenia Európskeho parlamentu a Rady (EÚ) 2016/679 z 27. apríla 2016 o ochrane fyzických osôb
                pri spracúvaní osobných údajov a o voľnom pohybe takýchto údajov, ktorým sa ruší smernica
                95/46/ES (všeobecné nariadenie o ochrane údajov) (ďalej aj len ako "nariadenie" alebo "GDPR") a
                zákona č. 18/2018 Z. z. o ochrane osobných údajov a o zmene a doplnení niektorých zákonov (ďalej
len ako „zákon“).</p>

                <p><strong>Kontakt na prevádzkovateľa:</strong> judolevice@gmail.com, tel.: +421 911 560 306</p>

                <h3 className="display-6 text-center">Plnenie informačnej povinnosti voči dotknutým osobám v súlade s čl. 13 a 14 nariadenia a § 19
a § 20 zákona</h3>

                <p>Zásady spravodlivého a transparentného spracúvania osobných údajov si vyžadujú, aby dotknutá
                osoba bola informovaná o spracovateľskej činnosti, účele spracúvania osobných údajov, jej právach a
                kontaktných údajoch prevádzkovateľa, prípadne jeho zodpovednej osoby.
                Informácie súvisiace so spracúvaním osobných údajov týkajúcich sa dotknutej osoby prevádzkovateľ
                poskytuje dotknutej osobe v čase získavania osobných údajov od dotknutej osoby, alebo ak sa
                osobné údaje získali z iného zdroja, v primeranej lehote po získaní osobných údajov v súlade s
nariadením a zákonom.</p>

                <h3 className="display-6 text-center">Účel spracúvania osobných údajov</h3>

                <p><strong>A. Evidencia členov športového klubu pre účely športovej výchovy a vzdelávania</strong></p>
                <div>
                    <div>Právny základ spracúvania: Zákon č. 440/2015 Z. z. o športe a o zmene a doplnení niektorých
zákonov, Zákon č. 83/1990 Zb. o združovaní občanov</div>
                    <div>Príjemcovia: organizátori súťaží, oprávnené orgány štátu</div>
                    <div>Doba uchovávania: osobné údaje uchovávame výlučne počas doby platnosti členstva</div>
                </div>

                <p className="pt-4"><strong>B. Evidencia členských príspevkov</strong></p>
                <div>Právny základ spracúvania: Zákon č. 83/1990 Zb. o združovaní občanov, Zákon č. 431/2002 Z. z. o
účtovníctve</div>
                <div>Príjemcovia: účtovník, daňový úrad, oprávnené orgány štátu</div>
                <div>Doba uchovávania: 10 rokov</div>

                <p className="pt-4"><strong>C. Propagácia športového klubu prostredníctvom webstránky, sociálnych médií a v
priestoroch telocvične.</strong></p>
                <div>Právny základ spracúvania: spracúvanie sa vykonáva podľa čl. 6 ods. 1 písm. a) Nariadenia Európskeho parlamentu a Rady (EÚ) 2016/679 o ochrane fyzických osôb pri spracúvaní osobných
údajov a o voľnom pohybe týchto údajov.</div>
                <div>Príjemcovia: osobné údaje sa neposkytujú tretím stranám</div>
                <div>Doba uchovávania: 5 rokov alebo po dobu odvolania súhlasu</div>

                <p><strong>Prenos osobných údajov do tretej krajiny alebo medzinárodnej organizácii:</strong> nevykonáva sa</p>

                <p><strong>Automatizované rozhodovanie vrátane profilovania:</strong> nevyužíva sa</p>

                <h3 className="display-6 text-center">Práva dotknutých osôb</h3>

                <p>Každá dotknutá osoba má právo na nasledovné:</p>

                <div><strong>Právo na prístup k osobným údajom (podľa čl. 15 Nariadenia)</strong></div>
                <div>Dotknutá osoba má právo získať od prevádzkovateľa potvrdenie o tom, či sa spracúvajú osobné
                údaje, ktoré sa jej týkajú. Ak prevádzkovateľ takéto osobné údaje spracúva, dotknutá osoba má právo
získať prístup k týmto osobným údajom a informácie o:</div>
                <ul>
                    <li>účele spracúvania osobných údajov,</li>
                    <li>kategórii spracúvaných osobných údajov,</li>
                    <li>identifikácii príjemcu alebo o kategórii príjemcu, ktorému boli alebo majú byť osobné údaje
                    poskytnuté, najmä o príjemcovi v tretej krajine alebo o medzinárodnej organizácii (ak je to
možné),</li>
                    <li>dobe uchovávania osobných údajov (ak to nie je možné, informáciu o kritériách jej určenia),</li>
                    <li>práve požadovať od prevádzkovateľa opravu osobných údajov týkajúcich sa dotknutej osoby, ich
                    vymazanie alebo obmedzenie ich spracúvania, alebo o práve namietať spracúvanie osobných
údajov,</li>
                    <li>práve podať v zmysle Nariadenia sťažnosť dozornému orgánu, ktorým je Úrad na ochranu
                    osobných údajov SR, alebo podať v zmysle Zákona návrh na začatie konania o ochrane
osobných údajov,</li>
                    <li>zdroji osobných údajov, ak sa osobné údaje nezískali od dotknutej osoby,</li>
                    <li>existencii automatizovaného individuálneho rozhodovania vrátane profilovania (vrátane informácií
                    o použitom postupe, ako aj o význame a predpokladaných dôsledkoch takého spracúvania
osobných údajov pre dotknutú osobu),</li>
                    <li> o primeraných zárukách týkajúcich sa prenosu, v prípade ak sa osobné údaje prenášajú do tretej
krajiny alebo medzinárodnej organizácii.</li>
                </ul>
                <div>Judo klub Levice Slovensko, o.z. poskytne dotknutej osobe kópiu osobných údajov, ktoré sa
                spracúvajú. Za opakované poskytnutie osobných údajov, o ktoré dotknutá osoba požiada, môže Judo
                klub Levice Slovensko, o.z. účtovať primeraný poplatok zodpovedajúci administratívnym nákladom.
                Ak dotknutá osoba podala žiadosť elektronickými prostriedkami, informácie sa poskytnú v bežne
používanej elektronickej podobe, pokiaľ dotknutá osoba nepožiadala o iný spôsob.</div>

                <div className="pt-4"><strong>Právo na opravu (podľa čl. 16 Nariadenia)</strong></div>
                <div>Dotknutá osoba má právo na to, aby prevádzkovateľ bez zbytočného odkladu opravil nesprávne osobné údaje, ktoré sa jej týkajú. So zreteľom na účel spracúvania osobných údajov má dotknutá
osoba právo na doplnenie neúplných osobných údajov.</div>


                <div className="pt-4"><strong>Právo na vymazanie (právo na zabudnutie podľa čl. 17 Nariadenia)</strong></div>
                <div>Dotknutá osoba má právo na to, aby prevádzkovateľ bez zbytočného odkladu vymazal osobné údaje,
ktoré sa jej týkajú, ak je naplnený niektorý z nasledovných dôvodov:</div>
                <ul>
                    <li> osobné údaje už nie sú potrebné na účely, na ktoré sa získavali alebo inak spracúvali,</li>
                    <li> dotknutá osoba odvolá súhlas (viď nižšie), na základe ktorého sa spracúvanie vykonáva, ak
neexistuje iný právny základ pre spracúvanie,</li>
                    <li> dotknutá osoba namieta voči spracúvaniu, ktoré je vykonávané na právnom základe verejného
                    záujmu alebo oprávneného záujmu (viď nižšie) a neprevažujú žiadne oprávnené dôvody na
spracúvanie,</li>
                    <li> osobné údaje sa spracúvali nezákonne,</li>
                    <li> osobné údaje musia byť vymazané, aby sa splnila zákonná povinnosť podľa práva Európskej
únie alebo práva Slovenskej republiky,</li>
                    <li> osobné údaje sa získavali v súvislosti s ponukou služieb informačnej spoločnosti podľa článku 8
od. 1 Nariadenia.</li>
                </ul>
                <div>Toto právo sa neuplatňuje, ak je spracúvanie osobných údajov potrebné napríklad na uplatnenie
                práva na slobodu prejavu a na informácie, na splnenie zákonnej povinnosti, alebo na splnenie úlohy
                realizovanej vo verejnom záujme alebo pri výkone verejnej moci zverenej prevádzkovateľovi,
                z dôvodov verejného záujmu v oblasti verejného zdravia, na účely archivácie vo verejnom záujme, na
                účely vedeckého alebo historického výskumu či na štatistické účely, na preukazovanie, uplatňovanie
alebo obhajovanie právnych nárokov.</div>


                <div className="pt-4"><strong>Právo na obmedzenie spracúvania (podľa čl. 18 Nariadenia)</strong></div>
                <div>Dotknutá osoba má právo na to, aby prevádzkovateľ obmedzil spracúvanie osobných údajov pokiaľ
ide o jeden z týchto prípadov:</div>
                <ul>
                    <li> dotknutá osoba napadne správnosť osobných údajov, a to počas obdobia umožňujúceho
prevádzkovateľovi overiť správnosť osobných údajov,</li>
                    <li> spracúvanie je protizákonné a dotknutá osoba namieta proti vymazaniu osobných údajov a žiada
namiesto toho obmedzenie ich použitia,</li>
                    <li> prevádzkovateľ už nepotrebuje osobné údaje na účely spracúvania, ale potrebuje ich dotknutá
osoba na preukázanie, uplatňovanie alebo obhajovanie právnych nárokov,</li>
                    <li> dotknutá osoba namietala voči spracúvaniu, ktoré je vykonávané na právnom základe verejného
                    záujmu alebo oprávneného záujmu (viď nižšie), a to až do overenia, či oprávnené dôvody na
strane prevádzkovateľa prevažujú nad oprávnenými dôvodmi dotknutej osoby.</li>
                </ul>
                <div>Ak dôjde k obmedzeniu spracúvania osobných údajov, prevádzkovateľ môže takéto osobné údaje
                ďalej len uchovávať alebo spracúvať na účely preukazovania, uplatňovania alebo obhajovania
                právnych nárokov, na ochranu práv iných osôb alebo z dôvodov dôležitého verejného záujmu. Iné
                spracúvanie možno vykonávať len so súhlasom dotknutej osoby.
                Ak by malo dôjsť k zrušeniu obmedzenia spracúvania osobných údajov, prevádzkovateľ o tejto
skutočnosti dotknutú osobu vopred informuje.</div>

                <div className="pt-4"><strong>Právo na prenosnosť údajov (podľa čl. 20 Nariadenia)</strong></div>
                <div>Dotknutá osoba má pri uplatňovaní svojho práva na prenosnosť údajov právo získať (v
                štruktúrovanom, bežne používanom a strojovo čitateľnom formáte) a preniesť osobné údaje priamo
                od jedného prevádzkovateľa druhému prevádzkovateľovi, pokiaľ je to technicky možné.
Právo na prenosnosť osobných údajov je možné uplatniť za predpokladu, že:</div>
                <ul>
                    <li> sa spracúvanie zakladá na právnom základe súhlas alebo zmluva, a</li>
                    <li> spracúvanie sa vykonáva automatizovanými prostriedkami.</li>
                </ul>

                <div className="pt-4"><strong>Právo namietať (podľa čl. 21 Nariadenia)</strong></div>
                <div>V prípade, ak je právny základ spracúvania osobných údajov splnenie úlohy realizovanej vo
                verejnom záujme (podľa čl. 6 ods. 1 písm. e) Nariadenia alebo oprávnený záujem (podľa čl. 6 ods. 1
                písm. f) Nariadenia, má dotknutá osoba právo tiež namietať proti spracúvaniu osobných údajov,
                ktoré sa jej týka, vrátane namietania proti profilovaniu.
                V prípade takéhoto namietania sprostredkovateľ nesmie ďalej spracúvať osobné údaje, pokiaľ
nepreukáže:</div>
                <ul>
                    <li> nevyhnutné oprávnené dôvody na spracúvanie, ktoré prevažujú nad záujmami, právami
a slobodami dotknutej osoby alebo</li>
                    <li> dôvody na preukazovanie, uplatňovanie alebo obhajovanie právnych nárokov.</li>
                </ul>


                <div className="pt-4"><strong>Automatizované individuálne rozhodovanie vrátane profilovania (podľa čl. 22 Nariadenia)</strong></div>
                <div>Dotknutá osoba má právo na to, aby sa na ňu nevzťahovalo rozhodnutie, ktoré je založené výlučne na
                automatizovanom spracúvaní, vrátane profilovania, a ktoré má právne účinky, ktoré sa jej týkajú alebo
ju podobne významne ovplyvňujú.</div>
                <div>Toto právo sa neuplatňuje, ak je rozhodnutie:</div>
                <ul>
                    <li> nevyhnutné na uzavretie alebo plnenie zmluvy medzi dotknutou osobou a prevádzkovateľom,</li>
                    <li> povolené právom Európskej únie alebo Slovenskej republiky alebo</li>
                    <li> založené na výslovnom súhlase dotknutej osoby.</li>
                </ul>

                <div className="pt-4"><strong>Právo na odvolanie súhlasu (podľa čl. 7 Nariadenia)</strong></div>
                    <div>V prípade, ak je spracúvanie osobných údajov založené na súhlase dotknutej osoby, dotknutá osoba
                    má právo kedykoľvek tento súhlas odvolať. Odvolanie súhlasu nemá vplyv na zákonnosť spracúvania
osobných údajov založeného na súhlase udelenom pred jeho odvolaním.</div>

                <p className="pt-4">Každá dotknutá osoba si môže uplatniť svoje práva prostredníctvom žiadosti, ktorá sa nachádza na
webovej stránke <strong>www.judolevice.sk</strong> a jej zaslaním na <strong>judolevice@gmail.com</strong>, alebo na poštovú
adresu: <strong>Judo klub Levice Slovensko, o.z., Ľ. Podjavorinskej 13, 934 01 Levice</strong>.</p>



            </div>

            <div className="pt-4"><strong>Dokumenty:</strong></div>
            <ul>
                <li> <a href="/files/gdpr-ziadost.pdf" download><FaFilePdf /> Žiadosť dotknutej osoby na uplatnenie jej práv</a></li>
            </ul>


        </div>

    )
}


export default GDPR
