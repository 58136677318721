import React, { useState, useEffect } from 'react'
import NavBar from '../components/NavBar';
import Error from '../components/Error';
import Loader from '../components/Loader';
import Footer from '../components/Footer';


function Judo(props) {
    const pageName = "Judo.js"
    const config = require('../config')
    const debug = config.DEBUG
    if(debug) { console.log(pageName) }

    const [completed, setCompleted] = useState(false)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        if(debug) { console.log(pageName + ' loading ...') }

        // things to do 

        if(debug) { console.log(pageName + ' loading done.') }
        setCompleted(true)
        setLoading(false)
        setError(false)
    }, [debug])



    // var content = <Error />
    // if (loading) { content = <Loader {...props} /> }    
    // else if (error) { content = <Error /> }
    // else if (completed && !error && !loading) { content = <DefaultContent /> }
    // else { content = <Error /> }


    return (
        <div className="container">
            <NavBar />

            {loading && <Loader />}
            {error && <Error />}
            {completed ? (<DefaultContent />) : (<Error />)}

            <Footer />
        </div>


    )

}

const DefaultContent = () => {
    return (

        <div>
            <div className="jumbotron my-4">

                <h1 className="display-4">História JUDA</h1>
           
            </div>

            <div id="historia">    
                <p>Z predhistorických a historických dianí až do dnešného dňa, v každej spoločnosti, v každom národe, boj a zápas získal veľmi dôležité postavenie. Bolo to dôsledkom samotnej podstaty človeka - jeho konania pre udržanie sa pri živote, pre zachovanie rodu, klanu, životného priestoru, lovišťa atď... Keď budeme posudzovať rôzne kontinenty, tak zistíme, že v spôsobe boja, vo vedení vojenských operácií a pod. sú veľmi veľké rozdiely. Najväčší kult boja a zápasu bol dosiahnutý na územiach juhovýchodnej Ázie, kde sa boj stal súčasťou národných tradícii.</p>
                <p>Japonci ako prvý na svete povýšili úpolové disciplíny do pozície umenia, pretože sa v nich rovnako ako v hudobnom a výtvarnom umení prejavujú inšpirácia, intuícia a cit. Japonským cieľom bolo samozrejme vychovať nie priemerného, ale kvalitného a uznávaného umelca.
                Judo ako šport vzniklo v Japonsku vyčlenením určitých techník z bojových umení Ju Jutsu a niektorých ďalších bojových umení. V prvom rade bolo nutné vyčleniť techniky, ktoré by mohli pri samotnom stretnutí spôsobiť súperovi zranenie. Čím je teda Ju Jutsu, o ktorom sa hovorí, že sa stalo otcom Judo? Ju Jutsu obsahuje techniky hodov, úchopov, škrtení, útokov na kĺby, úderov, bodnutí a kopov. Niekedy, okolo 17. storočia začali byť tieto techniky zostavované do systému a precvičované ako obyčajnými ľuďmi, tak aj samurajmi.</p>
                <p>Osobnosť, ktorá zohrala pri vzniku Judo najväčšiu úlohu, je osobnosť Jigora Kana.
                Jigoro Kano sa narodil 28. októbra 1860, napriek svojmu malému vzrastu a slabej telesnej konštrukcii sa rozhodol zaoberať štúdiom bojových umení.</p>
                <p>Počas niekoľkých rokov pravidelného cvičenia začína formovať systém, v ktorom chce skombinovať bojový tréning s psychickým a fyzickým rozvojom osobnosti. Snaží sa vytvoriť komplex fyzických aktivít, ktoré by boli vhodné nielen pre výcvik ozbrojených zložiek ako vojenské umenie, ale aby ako umenie boja malo pozitívum aj pre širokú verejnosť. Jigoro Kano začína tým, že v roku 1885 z Tenjinshinyo Ryu preberá a spracuváva Atemi waza a Katame waza a z Kito Ryu preberá a spracuváva Nage waza. Jigoro Kanovi sa podarilo povýšiť fyzický aspekt tréningu na úroveň intelektuálnej a morálnej výchovy, čím v podstate vytvoril a založil vlastnú školu Kodokan a v nej vyučoval svoj vlastný systém Jiu-Jitsu, ktorý nazval Judo.
                Keďže Jigoro Kano od začiatku kládol dôraz na duchovnú a filozofickú stránku, nestalo sa Judo iba jedným z nespočetného množstva bojových umení, no dostalo i vysoký spoločenský kredit.
                Niektoré pramene uvádzajú rok 1882 ako rok vzniku Judo. Je potrebné si však uvedomiť, že išlo o neustály proces objavovania, osvojovania, vznikania, rozvoja, zanikania a opätovného vznikania jednotlivých techník. Zásady techník Kodokan Judo boli stanovené v roku 1887, ale vnútorná náplň sa zdokonaľovala až do roku 1922 a v takej podobe, až na malé zmeny, sa zachovala až dodnes.</p>
                <p>Jednou z hlavných ideí zakladateľa bola aj táto myšlienka: "Judo je najlepší prostriedok na cvičenie tela i ducha, pričom metóda výcviku nevytvára nebezpečenstvo pre ľudský organizmus. Judo je prostriedkom čo najefektívnejšieho použitia fyzickej a duševnej sily. Toto cvičenie znamená súčasné zlepšovanie sa po psychickej a fyzickej stránke prostredníctvom sebaobranných techník a učenie sa podstate 'Cesty' prostredníctvom skúseností. Prvotným cieľom Judo je teda sebazdokonaľovanie, a tým učenie sa byť užitočný pre svet okolo seba".</p>
                <p>Jigoro Kano tento smer telesnej výchovy preferoval aj pre výcvik policajných a vojenských zložiek. Aby však Kodokan Judo bolo vyučované v ozbrojených zložkách, museli žiaci Kodokanu absolvovať konfrontačné zápasy v Dojo Saburi a tak obhájiť svoje postavenie medzi ostatnými školami Ju Jutsu. V roku 1885 policajný prefekt Mishima usporiadal prestížny turnaj medzi jednotlivými školami. Turnaj sa konal v policajnej hale Joakene v Shibe. Podľa niektorých dostupných historických materiálov Kodokan Judo uštedrilo svojim súperom zdrvujúcu porážku a týmto výrazným úspechom školy Jigoro Kana sa dosiahlo, že inštruktori Kodokanu mali výhradné právo podieľať sa na výcviku sebeobrany v ozbrojených zložkách.
                Na prelome storočí Kano a jeho žiaci, odchádzajú z Japonska (Sasaki Kichisaburo - Rakúsko-Uhorsko, Joshitaki Yamashita a Gunejiro Tomiti - USA, Jukio Tani - Veľká Británia ...) do celého sveta, aby tam šírili myšlienky Ó senseia. V rokoch 1933 a 1936 navštevuje Kano aj ČSR.</p>
                <p>V roku 1938 sa zúčastňuje 37. zasadnutia Medzinárodného olympijského výboru v Káhire, kde bolo rozhodnuté prideliť XII. Olympijské hry Japonsku (pre II. svetovú vojnu sa však neuskutočnili). Z Káhiry sa plaví loďou Hikawa Maru domov. Počas plavby Jigoro Kano ochorel na zápal pľúc a 4. mája 1938 zomiera. Na 57.generálnom zasadnutí Medzinárodného olympijského výboru v Ríme, ktoré prebiehalo 22.augusta 1960 v hoteli Excelsior bolo Judo s konečnou platnosťou prijaté medzi olympijské športy a bolo zaradené do programu Olympijských hier v Tokiu v roku 1964.</p>
            </div>


        </div>

    )
}


export default Judo
