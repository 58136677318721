import React, { useState, useEffect } from 'react'
import { FaFilePdf } from 'react-icons/fa';
import NavBar from '../components/NavBar';
import Error from '../components/Error';
import Loader from '../components/Loader';
import Footer from '../components/Footer';
import DvePercenta from '../components/DvePercenta';


function Percenta2(props) {
    const pageName = "2percenta.js"
    const config = require('../config')
    const debug = config.DEBUG
    if(debug) { console.log(pageName) }

    const [completed, setCompleted] = useState(false)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        if(debug) { console.log(pageName + ' loading ...') }

        // things to do 

        if(debug) { console.log(pageName + ' loading done.') }
        setCompleted(true)
        setLoading(false)
        setError(false)
    }, [debug])


    return (
        <div className="container">
            <NavBar />

            {loading && <Loader />}
            {error && <Error />}
            {completed ? (<DefaultContent />) : (<Error />)}

            <Footer />
        </div>


    )

}

const DefaultContent = () => {
    return (
        <div>
            <div className="jumbotron ">

                <h1 className="display-4">Venujte nám 2%</h1>
                <h2 className="display-6"><a href="/files/tlacivo-2percenta.pdf" download><FaFilePdf /> Predvyplnené tlačivo 2 percent</a></h2>
            </div>

            <div>

            <DvePercenta />
            {/* <img src="/images/2percenta.png" className="img-fluid" alt="2 percenta" /> */}
            
        

                {/* <p>Judo klub Levice Slovensko, o.z. so sídlom Ľ. Podjavorinskej 13, 934 01 Levice, IČO 42371694 (ďalej
                len ako "prevádzkovateľ") spracúva osobné údaje a zabezpečuje ich ochranu v súlade s požiadavkami
                Nariadenia Európskeho parlamentu a Rady (EÚ) 2016/679 z 27. apríla 2016 o ochrane fyzických osôb
                pri spracúvaní osobných údajov a o voľnom pohybe takýchto údajov, ktorým sa ruší smernica
                95/46/ES (všeobecné nariadenie o ochrane údajov) (ďalej aj len ako "nariadenie" alebo "GDPR") a
                zákona č. 18/2018 Z. z. o ochrane osobných údajov a o zmene a doplnení niektorých zákonov (ďalej
len ako „zákon“).</p>

                <p><strong>Kontakt na prevádzkovateľa:</strong> judolevice@gmail.com, tel.: +421 911 560 306</p>

                <h3 className="display-6 text-center">Plnenie informačnej povinnosti voči dotknutým osobám v súlade s čl. 13 a 14 nariadenia a § 19
a § 20 zákona</h3> */}

            </div>


        </div>

    )
}


export default Percenta2
