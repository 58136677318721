import React from 'react'

export const Loader = (loading) => {
  return (

    <div className={"modal fade bd-example-modal-lg" + (loading ? " show d-block" : " d-none")} tabIndex="-1" role="dialog">

      <div className="modal-dialog modal-dialog-centered d-flex justify-content-center" role="document">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>


  )
}

export default Loader;
