import React from 'react'
import { FaFilePdf } from 'react-icons/fa';

export default function DvePercenta() {

    return (
        <div>
<h3>Obraciame sa na Vás s prosbou – poukázať 2% z Vašich daní</h3>

<p>Aj tento rok nám môžete pomôcť poukázaním 2% (3%) zo svojich zaplatených daní v roku 2023. Nenechajte ich prepadnúť štátu, podporte radšej tých, ktorí Vám vedia dokázať, že Vaša podpora do športu sa vyplatila!</p>

<p>Neplatíte nič navyše, len využívate svoje zákonné právo dať 2 % (3%) zo svojich daní subjektom, ktoré sú tieto prostriedky využijú pre svoj ďalší rozvoj.</p>
            
<hr />
<h4 className="bg-silver"><a href="/files/tlacivo-2percenta.pdf" download><FaFilePdf /> Predvyplnené tlačivo 2 percent</a></h4>
<hr />

<h5>POKYNY NA POUKÁZANIE 2%:</h5>
<ol>
<li>Pre zamestnancov, ktorí si nepodávajú daňové priznanie:</li>
    <ul>
        <li>Váš zamestnávateľ Vám vykoná ročné zúčtovanie preddavkov na daň z príjmov a vystaví „Potvrdenie o zaplatení dane“.</li>
        <li>Z tohto „Potvrdenia“ si viete zistiť dátum zaplatenia dane a vypočítať:</li>
        <ul>
            <li>a) 2% z Vašej zaplatenej dane - ak ste v roku 2023 neboli dobrovoľníkom alebo dobrovoľnícky odpracovali menej ako 40 hodín. Táto suma však musí byť minimálne 3 €. </li>
            <li>b) 3% z Vašej zaplatenej dane - ak ste v roku 2023 odpracovali dobrovoľnícky viac ako  40 hodín a získate o tom Potvrdenie od organizácie/organizácií, pre ktoré ste v roku 2023 dobrovoľnícky pracovali.</li>
        </ul>
        <li>Vyplňte vyhlásenie o poukázaní 2 % (3%) dane. </li>
        <li>Najneskôr do 30. apríla 2024 pošlite/doručte formuláre daňovému úradu v mieste vášho bydliska (Vyhlásenie a Potvrdenie).</li>        
    </ul>
<li>Pre fyzické osoby, ktoré si samé podávajú daňové priznanie:</li>
    <ul>
        <li>Do 31. marca 2024  podajte daňové priznanie. Vyhlásenie je už súčasťou daňového priznania.</li>
    </ul>

<li>Pre právnické osoby:</li>
    <ul>
        <li>Do 31. marca 2024  podajte daňové priznanie. Vyhlásenie je už súčasťou daňového priznania.</li>
    </ul>
</ol>

{/* <h5>V ROKU 2024 PLÁNUJEME VAŠE 2% (3%) POUŽIŤ NA:</h5>
<ol>
    <li>podporovanie mladých judo talentov</li>
    <li>modernizáciu a rozširovanie našej telocvične, nakoľko záujem o tento šport neustále rastie</li>
    <li>nákup športového náčinia nevyhnutného na tréning</li>
    <li>financovanie turnajov našich reprezentantov doma i v zahraničí, kempov a sústredení</li>
    <li>motivovanie reprezentantov klubu lepším výsledkom</li>
</ol> */}

<h6>Ď A K U J E M E za Vašu podporu :)</h6>


        </div>
    )
}
