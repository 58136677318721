import React, { useState, useEffect } from 'react'
import NavBar from '../components/NavBar';
import Error from '../components/Error';
import Loader from '../components/Loader';
import Footer from '../components/Footer';
import { Field, Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import AuthService from "../services/auth.service";
import './Login.css'

const SignInSchema = Yup.object().shape({
    username: Yup.string()
      .lowercase()
      .email('Must be a valid email!')
      .required('Required!'),
    password: Yup.string()
      .min(8, 'Minimum 8 characters required!')
      .required('Required!'),
  })


function Login(props) {
    const pageName = "Login.js"
    const config = require('../config')
    const debug = config.DEBUG
    if(debug) { console.log(pageName) }

    const [completed, setCompleted] = useState(false)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        if(debug) { console.log(pageName + ' loading ...') }

        // things to do 

        if(debug) { console.log(pageName + ' loading done.') }
        setCompleted(true)
        setLoading(false)
        setError(false)
    }, [debug])



    return(
        <div className="container">
            <NavBar />
            
            { loading && <Loader /> }  
            { error && <Error /> }  
            { completed ? (<DefaultContent {...props} />) : (<Error />) }

            <Footer />
        </div>
        

    )

}

const DefaultContent = (props) => {
    const initialValues = {
        username: '',
        password: '',
    }      

    const handleSubmit = (values) => {
        // disable refresh page after submitting - THIS IS DONE AUTOMATICLY BY FORMIK
        // event.preventDefault() 
        console.log(values)
        const login = AuthService.login(values.username, values.password)
        if (login) {
            console.log('login OK')       
            props.history.push('/zoznam-prihlasenych');
            //useHistory.push("/zoznam-prihlasenych");     
        } else {
            console.log('not ok')
            //useHistory.push("/logout")
            props.history.push('/logout');
        }  


    }

    return (
        <div className="container"> 
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            // onSubmit={(values, { setSubmitting }) => {
            //     setTimeout(() => {
            //     alert(JSON.stringify(values, null, 2));
            //     setSubmitting(false);
            //     }, 400);
            // }}
            validationSchema={SignInSchema}
        >
        {({ dirty, isValid, errors, touched,  }) => (
            
        <Form>

        <div className="aaa">


            <div className="global-container">
            <div className="card login-form">
                <div className="card-body">
                <h3 className="card-title text-center">Pre registrovaných </h3>
                <div className="card-text">
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Uživatel</label>
                        <Field name="username" type="email" autoComplete="off" className="form-control" placeholder="Email" />
                        <div><ErrorMessage name="email" /></div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Heslo</label>
                        <Field name="password" type="password" autoComplete="off" className="form-control" placeholder="Password" />                
                        <div><ErrorMessage name="password" /></div>
                    </div>
                    <button type="submit" disabled={!isValid || !dirty} className="btn btn-primary btn-block">Prihlásiť sa</button>
                </div>
                </div>
            </div>
            </div>


        </div>

        </Form>
        )}

        </Formik>

        </div>

    )
}


export default Login
