import React from 'react';

const Error = () => {
  return (

    <div>
      <h1>error</h1>
    </div>


  )
}

export default Error;
