import React, { useState, useEffect } from 'react'
import NavBar from '../components/NavBar';
import Error from '../components/Error';
import Loader from '../components/Loader';
import Footer from '../components/Footer';


function JudoEtika(props) {
    const pageName = "JudoEtika.js"
    const config = require('../config')
    const debug = config.DEBUG
    if(debug) { console.log(pageName) }

    const [completed, setCompleted] = useState(false)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        if(debug) { console.log(pageName + ' loading ...') }

        // things to do 

        if(debug) { console.log(pageName + ' loading done.') }
        setCompleted(true)
        setLoading(false)
        setError(false)
    }, [debug])


    return (
        <div className="container">
            <NavBar />

            {loading && <Loader />}
            {error && <Error />}
            {completed ? (<DefaultContent />) : (<Error />)}

            <Footer />
        </div>


    )

}

const DefaultContent = () => {
    return (

        <div>
            <div className="jumbotron my-4">

                <h1 className="display-4">Etika a JUDO</h1>     

            </div>

            <div id="etika">
    
                <p>Etika ako pojem je nadradený pravidlám definujúcim dobro a zlo, čestné a nečestné, správne a nesprávne, mravné a nemravné. Etika nám pomáha fungovať a prežiť nášmu ľudskému spoločenstvu ako takému. Veľká väčšina etiky je spoločná všetkým športovcom, všetkým ľuďom sveta. Etika, to sú historicky, postupom času navŕšené nepísané pravidlá, ktoré determinujú naše správanie a naopak, naše správanie determinuje etiku.</p>

                <p>Trénerovi dávajú jeho žiaci veľa otázok. Jedna z nich je aj tá, čo má judista robiť, ako sa správať, ak sa dostane do existenčne vyhrotenej situácie, ak je ohrozené jeho zdravie, alebo zdravie a bezpečnosť jeho okolia. Našťastie otázky tohto znenia sa u nás zatiaľ vo väčšej miere riešia iba ako teoretický problém.
                Pre judistu by malo platiť, že v žiadnom prípade nesmie byť agresorom on. Musí si uvedomiť, že aj prejavy verbálnej agresie môžu byť zámienkou, pre alkoholom opojeného človeka, na násilné vyústenie konfliktu. "Nevyslovuj slová, ktoré môžu vyvolať nesváry. Vynasnaž sa uzmieriť a vyriešiť každý konflikt, aj ten najmenší." Ale aby sme sa vrátili k otázke, ktorú kladú žiaci. V situáciách, kde je agresor druhý, platia pre každého občana, teda aj judistu, ustanovenia platného zákonníka. Je už však na svedomí a etickej vyspelosti každého jedinca, aby urobil pre obranu a ochranu skutočne len to, čo je nevyhnutné. Útočník, ktorý prestal útočiť, prestáva byť agresorom a stáva sa ním ten, ktorý by mu chcel dokázať svoju prevahu. Z daného vyplýva, že etika nie je niečo na čo sa s opustením Dojo zabúda. Je to vlastne podstata nášho bytia. Etické pravidlá boli počas vývoja jednotlivých škôl kodifikované, v podobe určitých predpisov, morálnych kódexov, ktoré boli presne formulované a dbalo sa na ich striktné dodržiavanie. V jednotlivých školách Ju Jutsu mali tieto predpisy podobné ba až totožné znenie s predpismi budhizmu. Neboli v nich však obsiahnuté iba vyslovene etické normy, boli v nich zahrnuté aj nariadenia týkajúce sa pitia nápojov, stravy, dennej hygieny, pohlavného života... V niektorých starých školách Ju Jutsu dokonca platilo nepísané pravidlo, neučiť umenie boja nikoho, kto nie je budhista. Budhista je totiž vo svojej podstate človek asketický, neútočný, dobrý, má absolútnu úctu k starším, váži si všetko živé, proste - má v sebe mier. Čo je však najdôležitejším znakom etiky každého judistu je to, že všetky etické pravidlá sú mu vlastné, je s nimi vnútorne stotožnený a nedodržuje ich iba preto, že to niekto vyžaduje.</p>

                <p>Etika nie je dovtedy etikou, pokiaľ nie je najvnútornejším presvedčením. Ak ide o presvedčenie, etika sa stáva filozofiou života. Etika nie je etikou, ale iba nič nehovoriacimi slovami, ak sa obchádza, keď sa nik nedíva. A preto dodržiavanie týchto pravidiel je to najťažšie a najpodstatnejšie. Je jedným z hlavných motívov a úloh trénera Judo, aby sa snažil o vštepenie etických noriem a hodnôt svojim žiakom. Ďalším dôležitým faktorom je, aby sme nežiadali od zverencov niečo, čo nie sme schopný robiť my, alebo čo nerobíme. Učiteľ Judo, by nemal nikdy nič predstierať, pretože žiaci to vycítia a prestanú si vážiť samotného učiteľa a aj myslenie učiteľov, predkov. Ak chcem niečo vyžadovať, musím to v prvom rade robiť aj ja. Samozrejme, že etika Judo nie je v žiadnom rozpore s etikou života, ba práve naopak. A práve to, ako sa naši cvičenci správajú na verejnosti, by mala byť pre nás tá najdôležitejšia osobná úloha. Čo z toho ak vychováme človeka, ktorý sa vie správať len v Dojo? Často sa nám stáva, že cvičenci prichádzajú sa zaoberať Judo len pre svoju osobnú márnivú potrebu, ukojiť vlastné ego, dokázať si, že sú silnejší, ako druhí. Preto hlavnou úlohou trénera Judo je vštepovať zverencom úctu k druhému, vzájomný rešpekt, odbúrať u nich potrebu dominovať nad niekým iným.</p>

                <p>Zmyslom Judo je prekonať svoje ego, zvíťaziť nad sebou samým, nad vlastnou slabosťou. Iste bude mnoho ľudí namietať, že v randori ide o prekonanie druhého. Zápas s druhými je síce dôležitý, ale výhra za každú cenu nie je konečným cieľom. Preto orientovanosť myslenia dovnútra je tým, čo odlišuje pravého judistu od ostatných ľudí. Ak človek dospeje do určitého štádia vnútorného vývoja, nepotrebuje si dokazovať svoje kvality a nepotrebuje to dokazovať ani iným. Práve naopak, snaží sa zo svojich kvalít odovzdať druhým čo možno najviac. Ak človek učí niekoho iného, tým nestráca nič zo svojich skúseností. Učením druhých človek získava.</p>

                <h4><small>Etiketa v Jude</small></h4>
                <p>Ak berieme etiku ako najvnútornejšie zákonitosti správania sa jedinca, etiketa je súhrn príkazov, predpisov a nariadení týkajúcich sa vonkajšej, formálnej stránky správania sa určitej skupiny ľudí. Štruktúra etikety bola zostavená tak, aby niečo vyjadrovala. Je nám však jasné, že nie je takmer možné sa úplne stotožniť s etiketou tak, aby formálna podoba správania bola aj vnútorne, emocionálne prežitá. Tieto pravidlá, nariadenia a predpisy (10 Buddhových KAI, Musashiho DOKUKÓDÓ...), to nie sú proste predpisy, ktoré sa dajú pochopiť len rozumom, to sú predpisy, ktoré sa musia cítiť. Ak sme v niektorých prípadoch svedkami toho, že sa etiketa stáva prázdnym, nič nehovoriacim rituálom, nie je to chybou etikety ako takej, ale ľudí, ktorí ju praktizujú.</p>
                <p>Etiketa však môže byť účinným prostriedkom, najmä psychologickým, pre vybudovanie hodnotových rebríčkov v oblasti etiky. Dodržiavanie etikety, ak sa s ňou človek stotožní, mení do určitej miery aj jeho vnútorné postoje. Mohlo by sa zdať, že obmedzenia, ktoré sú vyvolané etiketou, musia u jedinca spôsobiť nechuť, odpor a averziu. U koho však dochádza k úplnému rozkolu medzi jeho vnútornými postojmi a medzi etiketou Judo, ten odchádza z Dojo sám. Je to vlastne v poriadku s princípmi Judo. Judo nikoho k ničomu nenúti, Judo je len prostriedkom pre formovanie, nie pre zmenu osobnosti. Všetci sú slobodní ľudia, majú sa možnosť rozhodnúť sami o odchode. My už dnes nevyžadujeme aby každý koho príjmeme do Dojo bol budhista. Chceme a snažíme sa z každého vychovať dobrého človeka.</p>

                <h4><small>Etika a etiketa</small></h4>            
                <p>Dva termíny, ktoré sa niekedy zamieňajú, inokedy stotožňujú. Vo všeobecnosti však možno povedať, že kde niet etiky, tam etiketa vyznieva falošne a stáva sa samoúčelnou. Na rozdiel od etiky, ktorá je tvorená históriou, etiketa históriou vytvorená bola. Etiketa predstavuje prepojenie cvičenca s históriou a tradíciami. Každý z nás je produktom historického vývoja. A len vtedy, ak pochopíme tento vývoj, môžme si uvedomiť svoje miesto, svoju cenu a svoju úlohu. Všetkým je nám jasné, že miesto a čas, v ktorom žijeme, ovplyvňujú naše myslenie, naše konanie, našu existenciu. Miesto nás ovplyvňuje svojimi národnými tradíciami a kultúrou. Čas, práve stupňom vývoja etiky.</p>
                <p>Etiketa, ktorú dodržiavame dnes, určite nie je presnou kópiou tej, podľa ktorej sa riadili, a ktorú si vážili starí majstri. Jednak je to spôsobené časom, jednak určitým spoločenským vývojom a v našich podmienkach najmä podmienkami a prostriedkami, ktoré sú nám k dispozícii. Rešpektovanie týchto pravidiel správania sa na maximálnu možnú mieru nie je teda len bezobsažnou hrou, ale je to duchovné spojenie sa so všetkými, ktorí Judo cvičili a ctili. Nedá sa to vysvetliť, nedá sa pochopiť prečo je dôležitý pocit identity s myslením hnutia a kontinuity s minulosťou, musí sa to cítiť. Práve toto spojenie sa s minulosťou je jednou z hodnôt, od ktorej by sa nemalo ustupovať. Etiketa nám navonok ukazuje, kto má kedy koho pozdraviť, kto kedy rozprávať, kto je akou autoritou a pod. Etiketa v Judo teda trénerovi umožňuje omnoho ľahšiu komunikáciu so zverencami.</p>
                <p>Pod vplyvom týchto pravidiel sú ľahšie ovládateľní. Veď už v starom japonskom prísloví sa hovorí: "Disciplína vedie k radosti." A je to skutočne tak. Pri striktnom dodržiavaní určitých pravidiel, pri železnej disciplíne, dochádza nakoniec k tomu, že cvičenci sa do Judo doslova zamilujú. Judo je ich životom aj láskou.</p>

            </div>


        </div>

    )
}


export default JudoEtika
