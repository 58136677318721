import React from 'react'
import {HashRouter as Router, Switch, Route} from "react-router-dom"
import AuthService from './services/auth.service'

import './App.css'
// import 'bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
//import 'bootstrap/dist/js/popper.min.js';

import Blank from './pages/Blank';
import GDPR from './pages/GDPR';
import Percenta2 from './pages/2percenta';
import Prihlaska from './pages/Prihlaska';
import Treningy from './pages/Treningy';
import Onas from './pages/Onas';
import Judo from './pages/Judo';
import JudoFilizofia from './pages/JudoFilizofia';
import JudoEtika from './pages/JudoEtika';
import JudoMoralnyKodex from './pages/JudoMoralnyKodex';
import SkusobnyPoriadokKyu from './pages/SkusobnyPoriadokKyu';
import SkusobnyPoriadokDan from './pages/SkusobnyPoriadokDan';
import Oznamy from './pages/Oznamy';
import Login from './pages/Login';
import Applications from './pages/Applications';
import Dokumenty from './pages/Dokumenty';

const App = () => {
  const pageName = "App.js"
  const config = require('./config')
  const debug = config.DEBUG
  if(debug) { console.log(pageName) }


  return (
    <Router>  
      <Switch>
        <Route exact path="/" component={Oznamy} />
        <Route exact path="/prihlaska" component={Prihlaska} />
        <Route exact path="/gdpr" component={GDPR} />
        <Route exact path="/2-percenta" component={Percenta2} />
        <Route exact path="/treningy" component={Treningy} />
        <Route exact path="/o-nasom-klube" component={Onas} />
        <Route exact path="/skusobny-poriadok-kyu" component={SkusobnyPoriadokKyu} />
        <Route exact path="/skusobny-poriadok-dan" component={SkusobnyPoriadokDan} />
        <Route exact path="/judo" component={Judo} />
        <Route exact path="/judo-filozofia" component={JudoFilizofia} />
        <Route exact path="/judo-etika" component={JudoEtika} />
        <Route exact path="/judo-moralny-kodex" component={JudoMoralnyKodex} />
        <Route exact path="/judo-moralny-kodex" component={JudoMoralnyKodex} />
        <Route exact path="/dokumenty" component={Dokumenty} />
        
        <Route exact path="/login" component={Login} />
        <Route exact path="/logout" component={LogoutRoute} />
        <PrivateRoute exact path="/zoznam-prihlasenych" component={Applications} />            
        <Route path={'*'} component={Blank}  />            
      </Switch>
    </Router>
  )
}


const LogoutRoute = ({...rest}) => {
  AuthService.logout()

  return (
    <Route {...rest} render={props =>
          props.history.push("/login")
      }
    />
  )

}

const PrivateRoute = ({ component: Component, ...rest }) => {
  
  return (
    <Route {...rest} render={props =>
        AuthService.isAuthenticated() === true 
        ? 
          <Component {...props} />
        :
          props.history.push("/logout")
        
      }
    />
  )
}

export default App;
