import React, { useState, useEffect } from 'react'
import NavBar from '../components/NavBar';
import Error from '../components/Error';
import Loader from '../components/Loader';
import Footer from '../components/Footer';

function JudoMoralnyKodex(props) {
    const pageName = "JudoMoralnyKodex.js"

    const config = require('../config')
    const debug = config.DEBUG
    if(debug) { console.log(pageName) }

    const [completed, setCompleted] = useState(false)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        if(debug) { console.log(pageName + ' loading ...') }

        // things to do 

        if(debug) { console.log(pageName + ' loading done.') }
        setCompleted(true)
        setLoading(false)
        setError(false)
    }, [debug])

    return (
        <div className="container">
            <NavBar />

            {loading && <Loader />}
            {error && <Error />}
            {completed ? (<DefaultContent />) : (<Error />)}

            <Footer />
        </div>


    )

}

const DefaultContent = () => {
    return (

        <div>
            <div className="jumbotron my-4">

                <h1 className="display-4">Morálny kódex a JUDO</h1>     

                {/* <blockquote class="quote-card text-center">
                    <p>"Najlepšie využitie energie" a "Vzájomný blahobyt"</p>
                    <cite>Jigoro Kano hovorí o dvoch hlavných princípoch v Judo, ktoré sa od vzniku umenia objavili:</cite>
                </blockquote> */}
           
            </div>

            <div id="moralnyKodex">
    
                <p>Často sa stretávame s názorom, že niektoré športy sú manifestáciou agresivity. Mnohí ľudia vidia v týchto športoch len neestetické, agresívne aspekty. Lukostreľba, šerm, zápasenie, box, karate, Judo, ... Áno, v skutočnosti tieto športy ponúkajú nevyvrátiteľné dôkazy agresívneho správania. Niektorí sociológovia tvrdia, že toto agresívne správanie je výsledkom kultúrnej indokrinácie.</p>
                <p>Všetky tieto javy a aspekty sú však v úplnom protikade a protirečení vyhláseniam ľudí, ktorí sú v spojení s bojovými umeniami. Prečo judisti tvrdia, že štúdiom Judo priviedli svoje "ja" k vyššej morálke, k menšej násilnosti, k mierumilovnosti? Najprv si musíme ujasniť význam slov násilie a agresivita. Omyl v posudzovaní Judo ako športu vychovávajúceho k násiliu pramení práve zo zamieňania týchto dvoch pojmov. "Z hľadiska psychologického poňatia športovej činnosti sa agresivita v športe chápe ako neodlučiteľná súčasť športového boja. Agresivita znamená útočnosť a bez tej sa športová bojovnosť nezaobíde." Agresivita je teda útočnosť, nie násilie. V bežnom živote by akýkoľvek rýchly pohyb, úchop či podmet mohol znamenať násilie, nie však v Judo, ktoré je obmedzované pravidlami aby nedošlo k zraneniu. V Judo dochádza k oslobodeniu mysle od očakávania, že sa niečo podobné môže stať. Preto ak chceme zistiť, či Judo plodí násilie, je nedostatočné sledovať len blízkosť tiel a rýchlosť pohybov. Ak zoberieme akýkoľvek prípad, je nám jasné, že východné "bojové športy" nemajú negatívne vplyvy, ale naopak smerujú k znižovaniu tendencie násilia a pôsobia ako brzdiaci činiteľ. Judo čoraz častejšie prijíma sebakontrolu ako základný princíp, preto je veľký predpoklad, že judisti a športovci iných úpolových športov sa v budúcnosti stanú najdisciplinovanejšími športovcami.</p>
                
                <h4><small>Morálny kódex judistu</small></h4>
                <strong>
                <ul>
                    <li>Zdvorilosť</li>
                    <li>Odvaha</li>
                    <li>Úprimnosť</li>
                    <li>Sebakontrola</li>
                    <li>Česť</li>
                    <li>Skromnosť</li>
                    <li>Priateľstvo</li>
                    <li>Úcta</li>
                </ul>
                </strong>
            </div>



        </div>

    )
}


export default JudoMoralnyKodex
