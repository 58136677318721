import React, { useState, useEffect } from 'react'
import NavBar from '../components/NavBar';
import Error from '../components/Error';
import Loader from '../components/Loader';
import Footer from '../components/Footer';


function SkusobnyPoriadokDan(props) {
    const pageName = "SkusobnyPoriadokDan.js"
    const config = require('../config')
    const debug = config.DEBUG
    if(debug) { console.log(pageName) }

    const [completed, setCompleted] = useState(false)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        if(debug) { console.log(pageName + ' loading ...') }

        // things to do 

        if(debug) { console.log(pageName + ' loading done.') }
        setCompleted(true)
        setLoading(false)
        setError(false)
    }, [debug])



    return (
        <div className="container">
            <NavBar />

            {loading && <Loader />}
            {error && <Error />}
            {completed ? (<DefaultContent />) : (<Error />)}

            <Footer />
        </div>


    )

}

const DefaultContent = () => {
    return (
        <div>
            <div className="jumbotron my-4">

                <h1 className="display-4">Skúšobný poriadok DAN (video)</h1>
            </div>


            <div className="container">
                <div className="row">

                    <div className="col-4 p-2">
                        <div className="embed-responsive embed-responsive-4by3">
                            <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/YOcVfmmMBLY" title="YOcVfmmMBLY1" />
                        </div>
                    </div>

                    <div className="col-4 p-2">
                        <div className="embed-responsive embed-responsive-4by3">
                            <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/-6g83MgiLMI" title="YOcVfmmMBLY2" />
                        </div>
                    </div>

                    <div className="col-4 p-2">
                        <div className="embed-responsive embed-responsive-4by3">
                            <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/UDwYdzUZV0w?ecver=1" title="YOcVfmmMBLY3" />
                        </div>
                    </div>

                    <div className="col-4 p-2">
                        <div className="embed-responsive embed-responsive-4by3">
                            <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/ePqTWKsYgsI" title="YOcVfmmMBLY4" />
                        </div>
                    </div>

                    <div className="col-4 p-2">
                        <div className="embed-responsive embed-responsive-4by3">
                            <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/N-NoS3VYxUE" title="YOcVfmmMBLY5" />
                        </div>
                    </div>


                    <div className="col-4 p-2">
                        <div className="embed-responsive embed-responsive-4by3">
                            <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/qn5I23bgCDU" title="YOcVfmmMBLY6" />
                        </div>
                    </div>

                    <div className="col-4 p-2">
                        <div className="embed-responsive embed-responsive-4by3">
                            <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/RRuShMdNSnM" title="YOcVfmmMBLY7" />
                        </div>
                    </div>

                    <div className="col-4 p-2">
                        <div className="embed-responsive embed-responsive-4by3">                        
                            <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/xUr_kpZ1fNk" title="YOcVfmmMBLY8" />
                        </div>
                    </div>

                    <div className="col-4 p-2">
                        <div className="embed-responsive embed-responsive-4by3">
                            <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/yGdJKwkFs1A" title="YOcVfmmMBLY9" />
                        </div>
                    </div>

                </div>

            </div>



    </div>

    )
}


export default SkusobnyPoriadokDan
