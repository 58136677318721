import React, { useState, useEffect } from 'react'
import NavBar from '../components/NavBar';
import Error from '../components/Error';
import Loader from '../components/Loader';
import Footer from '../components/Footer';


function Onas(props) {
    const pageName = "Onas.js"
    const config = require('../config')
    const debug = config.DEBUG
    if(debug) { console.log(pageName) }

    const [completed, setCompleted] = useState(false)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        if(debug) { console.log(pageName + ' loading ...') }

        // things to do 

        if(debug) { console.log(pageName + ' loading done.') }
        setCompleted(true)
        setLoading(false)
        setError(false)
    }, [debug])


    return (
        <div className="container">
            <NavBar />

            {loading && <Loader />}
            {error && <Error />}
            {completed ? (<DefaultContent />) : (<Error />)}

            <Footer />
        </div>


    )

}

const DefaultContent = () => {
    return (
        <div>
            <div className="jumbotron my-4">
                <div className="row m-0 p-0">

                    <div className="col-8">
                        <h1 className="display-4">Informácie o našom klube</h1>
                        <h2 className="display-6">a kontaktné údaje</h2>
                    </div>

                    <div className="col-4 text-right">
                    <p><strong>Trénujeme:</strong> ul. M.R.Štefánika 1 (OD Pokrok)</p>
                    <div>
                        <div><strong>účet:</strong> SK31 0200 0000 0034 6761 8051</div>
                        <div><strong>IČO:</strong> 42371694</div>
                        <div><strong>DIČ:</strong> 2024176143</div>
                    </div>
                    </div>

                </div>
            </div>

            <div id="trenery">
                <h3 className="display-6">Tréneri</h3>

                <div className="container-fluid">
                <div className="row ">
                    <div className="col-xl-4 col-md-6 col-12 mb-4">
                    <div className="card text-center rounded-0 border-0" id="card1">
                        <div className="hex-img mt-5 mb-4"> <img src="/images/trener-lajo.png" alt="Ľudovít Kossuth" /></div>
                        <h2 className="card-name">Ľudovít Kossuth</h2>
                        <p className="small"><strong>3. DAN</strong></p>
                        <p className="position">
                            0911 560 306<br />
                            judolevice@gmail.com
                        </p>
                        
                    </div>
                    </div>

                    <div className="col-xl-4 col-md-6 col-12 mb-4">
                    <div className="card text-center rounded-0 border-0" id="card1">
                        <div className="hex-img mt-5 mb-4"> <img src="/images/trener-michal.png" alt="Michal Fedor" /></div>
                        <h2 className="card-name">Michal Fedor</h2>
                        <p className="small"><strong>3. DAN</strong></p>
                        <p className="position">
                            0905 380 059<br />
                            judolevice@gmail.com
                        </p>
                    </div>
                    </div>

                    <div className="col-xl-4 col-md-6 col-12 mb-4">
                    <div className="card text-center rounded-0 border-0" id="card1">
                        <div className="hex-img mt-5 mb-4"> <img src="/images/trener-oliver.jpg" alt="Oliver Kossuth" /></div>
                        <h2 className="card-name">Oliver Kossuth</h2>
                        <p className="small"><strong>1. DAN</strong></p>
                        <p className="position">
                            0911 560 306<br />
                            judolevice@gmail.com
                        </p>
                    </div>
                    </div>

                </div>
                </div>




            </div>



            <div id="historia">
                <hr />
                <h3 className="display-6">História klubu</h3>
    
                <p>História Levického džuda sa začala písať v roku 1973 keď Zbigniev Górlicki  začal s trénovaním džuda v Leviciach, pod názvom Štart Levice. Po odchode trénera Górlického do Nových Zámkov sa stal  trénerom Michal Fedor. On založil  klub  Slovan agro Levice, kde  bol hlavným trénerom, predsedom bol  Z. Didi st., a hospodárom L. Puckaller. Ďalšími trénermi boli p. Ďurík, p. M. Cserhelyi, p. Mík. p.J.Holečko. Klub dosahoval veľmi pekné výsledky na krajských, československých  a medzinárodných turnajoch. Fungovalo to až do roku 1989  a po odchode trénera M. Fedora  džudo v Leviciach zaniklo.</p>
                <p>Až v roku 2009 na podnet M. Fedora sme  začali zase s trénovaním džuda ako veteráni, s džudistami Z. Didi, L. Puckaller , M. Cserhelyi a Ľ. Kossuth.</p>
                <p>Po rokoch vznikla myšlienka znovu zaloziť džudo klub v Leviciach a začať trénovať a venovať sa tomuto peknému športu. V našom záujme je nadviazať na predošlé úspechy klubu a znovu zviditeľniť džudo v Leviciach. Klub vznikol  19.decembra 2014 po absolvovaní všetkých administratívnych úkonov sme sa stali členmi SZJ. Predsedom klubu sa stal Ľ. Kossuth a podpredsedom G. Csala. V klube sú tri skupiny cvičiacich. Trénermi sú p. Fedor 2.Dan a p. Kossuth 3.Dan. V prvej skupine trénujú predškoláci vo veku od 5 rokov, v MŠ na Pereckej ulici u ktorých sa rozvíjajú pohybové danosti formou rôznych cvičení a hier. Druhú, najpočetnejšiu skupinu tvoria žiaci, ktorí si osvojujú a zdokonaľujú techniky džuda, pričom získavajú svoje prvé žiacke stupne technickej vyspelosti KYU a zároveň sa zúčastňujú aj turnajov. V tretej skupine sú dospelí a st. žiaci kde tréningy sú už pri väčšej fyzickej záťaži.</p>
    
            </div>

        </div>

    )
}


export default Onas
